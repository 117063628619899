@import '../../styles/variables.scss';
.display-none {
  display: none !important;
}

.expand-more-icon {
  right: 0.625rem;
  position: absolute;
  z-index: 1;
  stroke: $rekap_grey--x-dark;
}

.icon-btn {
  position: absolute;
  right: 0.625rem;
}

.menu-item {
  color: $rekap_grey--light !important;
}

.select-input {
  background-color: $rekap_white;
  border-color: $rekap_grey--light;
}
