@import '../../../../styles/variables.scss';

.how-to-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $rekap_black;

  .image-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .how-to-photo {
    min-height: 300px;
    width: 300px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .text-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    .text-body {
      width: 90%;

      margin-top: 28px !important;
      margin-bottom: 40px !important;
    }
  }
}

.carousel-title {
  color: $rekap_grey--dark;
}

@media (max-width: 800px) {
  .text-container {
    width: 90%;
  }

  .how-to-content-container {
    h3 {
      font-size: 30px;
      text-align: left;
    }
  }
}

@media (max-width: 780px) {
  .how-to-content-container {
    display: flex;
    flex-direction: column !important;

    .text-container {
      width: 90%;

      h4 {
        font-weight: bold;
        text-align: center;
      }

      .text-body {
        text-align: center;
        width: 100%;
        font-size: 20px;
      }
    }

    h3 {
      font-size: 28px;
    }
  }
}
