@import '../../style.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.suspended-container {
  &__modal {
    margin-top: 1.5rem;
  }
  &__body-text {
    @include text-styles__body(1);

    .email-text {
      @include text-styles__button(1);
      color: $rekap_teal;
    }
  }

  &__button-container {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .btn--secondary {
      box-sizing: border-box;
      height: 2rem;
      border: 1px solid $rekap_grey--x-dark;
      color: $rekap_grey--x-dark;
      margin-top: 0;
      @include text-styles__button(2);
    }
  }

  .kaliber-modal-container {
    .kaliber-modal-content-container {
      width: 17rem;
      height: 22rem;
    }

    .kaliber-modal-header-text {
      color: $rekap_black;
    }
  }
}

.credential-container {
  width: 100%;
  &__form {
    width: 374px;

    display: flex;
    flex-direction: column;
    margin: 1.125rem auto;
    background: $rekap_white;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
  }
  &__title {
    text-align: center;
    font-size: 1.4375rem;
    line-height: 1.22;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__logo {
    margin-left: 0.4375rem;
    width: 10.0563rem;
    height: 1.5rem;
  }
}
.credential-form {
  padding: 0 24px;

  &__header {
    @include text-styles__title(1, medium);
    text-align: center;
    margin: 1rem 0 0;
  }

  &__sub-header {
    @include text-styles__body(1);
    margin: 0.4375rem 0 1.5rem;
  }

  &__textbox {
    margin-top: 1rem;
  }

  &__textbox-email {
    margin: 24px 0 1rem;
  }

  &__label {
    margin: 0 0 0 0;
    width: 100%;
    height: 1rem;
    font-size: 0.875rem;
    line-height: 1.14;
    letter-spacing: 0.0156rem;
    color: #b4bbc3;
  }
  &__email {
    margin: 0.1875rem 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    text-overflow: ellipsis;
    letter-spacing: 0.0313rem;
    border: 0.0625rem solid #dadde1; // will be updated with CPT codes styling changes
    border-radius: $borderRadius;
    padding-left: 0.75rem;
    height: 3rem;
    display: flex;
    align-items: center;
    background-color: #ebecee;
    color: #b4bbc3;
  }

  &__btn {
    width: 100%;
    margin: 1.5rem auto 1rem;
  }

  &__forgot-password {
    text-align: right;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.33;
    margin: 0.5rem 0.5rem 1.875rem 0;

    letter-spacing: 0.025rem;
    color: $rekap_teal;
  }

  &__error {
    color: $rekap_red;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.0094rem;
    margin-bottom: 1rem;
  }
}
.modal-text {
  text-align: center;
}

.msg {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.0156rem;
  color: $rekap_black;
  margin-bottom: 0.8rem;

  &--credential {
    margin: 0.5rem 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
  }

  &--link {
    margin: 0 0.1875rem;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.025rem;
    color: $rekap_teal;
    width: fit-content;
  }

  &--agreement {
    text-align: left;
    width: 23.375rem;
    margin: 1.6875rem auto;
  }
}

@media (min-width: $tabletWidth) {
  .credential-container {
    &__form {
      width: 24.25rem;
      margin: 1.5rem auto;
    }
    &__title {
      font-size: 2.0625rem;
      line-height: 1.21;
      letter-spacing: 0.0156rem;
    }

    &__logo {
      width: 14.25rem;
      height: 2.125rem;
    }
  }

  .credential-form {
    padding: 0 1.5rem;
    &__btn {
      margin: 1.5625rem auto 1rem;
    }
  }

  .msg {
    &--agreement {
      width: 24.25rem;
    }
  }

  .suspended-container {
    &__button-container {
      .btn--secondary {
        box-sizing: border-box;
        width: 6.5rem;
        height: 2.5rem;
        @include text-styles__button(1);
      }
    }

    .kaliber-modal-container {
      .medium {
        width: 42.5rem;
      }

      .kaliber-modal-content-container {
        padding: 1.5rem;
        height: 12rem;
        .kaliber-modal-title-icon-container {
          .kaliber-modal-header-icon img {
            width: 2rem;
            height: 2rem;
          }
        }
        .kaliber-modal-close-icon {
          top: 1.5rem;
          right: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .credential-container {
    &__form {
      width: 36.75rem;
      margin: 2.5rem auto 0;
      padding: 0 2rem;
    }
    &__title {
      font-size: 2.0625rem;
      line-height: 1.21;
      letter-spacing: 0.0156rem;
    }

    &__logo {
      width: 14.25rem;
      height: 2.125rem;
    }
  }

  .credential-form {
    &__header {
      margin: 1.5rem 0 0rem;
    }
    &__btn {
      margin: 1.5625rem auto 1.5rem;
    }
  }

  .msg {
    &--credential {
      margin: 0.5rem 0 1.5rem;
    }
    &--agreement {
      text-align: center;
      width: 36.75rem;
      line-height: 1.5;
      letter-spacing: 0.15px;
      margin: 2.125rem auto;
    }
  }
}
