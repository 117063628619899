@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/border';
@import '../../../../styles/mixins/typography';

.patient-demographics {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__info {
    width: 12.5rem;
    height: 2.75rem;
    display: flex;
    flex-direction: column;
  }
  &__label {
    width: 100%;
    @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
    margin-bottom: 0.25rem;
  }
  &__text {
    width: 100%;
    @include text-styles__body(1, 'medium', $rekap_black);
    &--disabled {
      @include text-styles__body(1, 'normal', $rekap_grey--x-dark, 'italic');
    }
  }
}

.edit-patient-demographics-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .k-text-field__label-container {
    height: 1rem;
    @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
  }

  &__name-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;

    .middle-name,
    .last-name {
      width: 100%;
    }
  }

  .datepicker-size {
    .SingleDatePickerInput__withBorder {
      box-sizing: border-box;
      height: 3rem;
      width: 100%;
    }

    .SingleDatePickerInput_calendarIcon {
      text-align: right;
    }
  }

  .edit-patient-demographics-form--aditional-information {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__header {
      @include text-styles__headline(3);
    }

    &__divider {
      border-bottom: 1px solid $rekap_grey--light;
    }
  }
}

.edit-modal__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .btn--cancel,
  .btn--primary {
    height: 2rem;
    box-sizing: border-box;
    @include text-styles__button(2);
  }
}

@media (min-width: $tabletWidth) {
  .patient-demographics {
    &__container {
      flex-direction: row;
    }
  }
}

@media (min-width: $tabletWidth) {
  .edit-patient-demographics-form {
    &__name-fields {
      flex-direction: row;
      gap: 1.5rem;
      align-items: flex-start;

      .middle-name,
      .last-name {
        margin-top: 1.25rem;
      }
    }

    .preferred-name,
    .datepicker-size,
    .ks-wrapper {
      width: 21.75rem;
    }
  }

  .edit-modal__buttons {
    flex-direction: row-reverse;

    .btn {
      &--cancel,
      &--primary {
        @include text-styles__button(1);
        height: 2.5rem;
        max-width: none;
      }

      &--cancel {
        width: 7.5rem;
      }

      &--primary {
        width: 11.25rem;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .edit-modal__buttons {
    flex-direction: row-reverse;
  }
}
