@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/_typography.scss';
@import '../../../../styles/mixins/_border.scss';

.image-review-page {
  display: flex;
  flex-direction: column;

  .image-review-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .image-phase-legend {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &__item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      &__value {
        @include text-styles__body(1, 'medium');
      }
    }

    .quick-note-bulk-select {
      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.625rem;
        padding: 1.5rem 2rem;
        border: 1px solid $rekap_teal;
        border-radius: $borderRadius--large;
        background-color: $rekap_green--x-light;
      }

      &__message {
        margin: 0;
        padding: 0;
        @include text-styles__body(1);
      }
    }

    .image-grid {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}

.image-grid-error {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__text {
    @include text-styles__body(1);
  }

  &__button {
    display: flex;
    align-self: flex-end;
  }
}

.saving-snackbar {
  z-index: $modalZIndex;
}

@media (min-width: $tabletWidth) {
  .image-review-page {
    .image-review-container {
      .image-phase-legend {
        flex-direction: row;
        gap: 2.5rem;
      }

      .quick-note-bulk-select {
        &__button {
          width: $button-width--x-large;
        }
      }

      .image-grid {
        display: grid;
        grid-template-columns: repeat(3, 212px);
        align-items: stretch;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .image-review-page {
    .image-review-container {
      .image-grid {
        grid-template-columns: repeat(4, 266px);
      }
    }
  }
}
