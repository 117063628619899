@import '../../styles/variables.scss';

.container-terms {
  p li b address {
    font-size: 18px;
    line-height: normal;
    word-spacing: normal;
  }

  h3 {
    display: block;
    color: $rekap_black;
    text-align: center;
    font-size: 50px;
    padding: 70px;
    font-weight: 500;
  }

  a {
    color: $rekap_teal--x-light;
  }

  width: 910px;
  position: relative;
  margin: 0px 0px 16px calc((100% - 980px) * 0.5);
  left: 38px;
  grid-area: 2 / 1 / 3 / 2;
  justify-self: start;
  align-self: start;
}
