@import '../../../../styles/variables.scss';

.bio-container {
  display: flex;
  margin: 52px 0;
  .bio-right-col {
    width: 360px;

    .surgeon-photo {
      width: 100%;
    }
    .surgeon-quote {
      color: $rekap_grey--x-dark;
      margin: 32px 0 34px 0;
    }

    .surgeon-contact {
      color: $rekap_black;
      &-label {
        color: $rekap_grey--dark;
        font-size: 20px;
      }
    }
  }
  .bio-left-col {
    width: 550px;
    margin-right: 40px;
    .bio-title {
      display: block;
      margin-bottom: 25px;

      h4 {
        color: $rekap_black;
      }
    }
  }

  .bio-title-m {
    display: none;
  }
}

.surgeon-name {
  color: $rekap_black;
  font-size: 40px;
}

@media (max-width: 700px) {
  .bio-container {
    flex-wrap: wrap-reverse;
    justify-content: center;
    .bio-right-col {
      width: 95%;

      .surgeon-contact {
        margin-top: 20px;
      }

      .surgeon-photo {
        width: 100%;
        max-width: 200px;
      }
      .surgeon-quote {
        margin: 32px 0 34px 0;
        font-style: italic;
        text-align: center;
      }
    }

    .bio-title {
      display: none !important;
    }

    .bio-left-col {
      width: 95%;
      margin-top: 25px;
      margin-right: 0px;
    }

    .bio-title-m {
      display: block;
      margin-bottom: 25px;
      text-align: left;
      .MuiTypography-h3 {
        text-align: left;
      }
    }

    .bio-contact-quote {
      display: flex;
      flex-wrap: wrap-reverse;
    }
  }
}
