@import '../../../../../../styles/mixins/typography';
@import '../../../../../../styles/variables.scss';

.status-chip {
  border-radius: 3.75rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 8.25rem;
  min-width: fit-content;

  &__text {
    @include text-styles__button(2);
    padding: 0.5rem 0.75rem;
  }

  &--default {
    background-color: $rekap_grey--x-light;
    color: $rekap_grey--x-dark;
  }

  &--red {
    background-color: $rekap_red--x-light;
    color: $rekap_red--dark;
  }

  &--yellow {
    background-color: $rekap_yellow--light;
    color: $rekap_brown;
  }

  &--green {
    background-color: $rekap_green--light;
    color: $rekap_green;
  }

  &--primary {
    background-color: $rekap_teal--xx-light;
    color: $rekap_teal;
  }

  &--disabled {
    background-color: $rekap_grey;
    color: $rekap_grey--dark;
  }
}
