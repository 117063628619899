@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles//mixins/_typography.scss';

.review-surgery-details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .surgery-details-row {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .item {
      &__label {
        @include text-styles__body(2);
        height: 1rem;
        margin-bottom: 0.25rem;
      }

      &__value {
        @include text-styles__body(1, 'medium');
      }
    }
  }
}

.ks-container {
  max-width: 100%;
}

@media (min-width: $tabletWidth) {
  .review-surgery-details-container {
    .surgery-details-row {
      flex-direction: row;
      gap: 2.5rem;
      min-width: 12.5rem;
    }
  }
}
