@import '../../styles/mixins/button';
@import '../../styles/variables.scss';
@import '../../styles/mixins/center';
// list of global buttons
.btn {
  &--primary {
    @include button($rekap_teal);
    &--small {
      @include button($rekap_teal, true);
    }
  }

  &--secondary {
    @include button($rekap_white, false, true);
    &--small {
      @include button($rekap_white, true, true);
    }
  }

  &--cancel {
    @include button($rekap_white, false, true, $rekap_grey--x-dark);
    &--small {
      @include button($rekap_teal, true);
    }
  }

  &--danger {
    @include button($rekap_red);
  }

  &--danger--secondary {
    @include button($rekap_white, false, true, $rekap_red);
  }

  &--warning {
    @include button($rekap_yellow);
  }

  &--full-width {
    width: $button-width--full;
  }
}

.btn--loading {
  background-color: $rekap_teal--dark;
  pointer-events: none;
}

.btn--loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: btn--loading-spinner 1s ease infinite;
}
.btn--loading .btn__text {
  visibility: hidden;
  opacity: 0;
}

@keyframes btn--loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
