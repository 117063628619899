@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.kl-navbar-container {
  box-shadow: 0 1px 5px $rekap_grey--light;
  position: fixed;
  width: 100%;
  display: flex;
  top: 0;
  justify-content: center;
  background-color: $rekap_blue--dark;
  z-index: 1000;
  height: 4rem;

  .icon {
    color: $rekap_white;
  }
}

.kl-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0.5rem;
  width: 100%;
  max-width: $desktopWidth;
  min-width: 20.5rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-left: auto;
  .kl-logo-link {
    display: flex;
    align-items: center;
    border-bottom: none;
  }

  .kl-logo {
    width: 8.25rem;
    height: 1.25rem;
    align-self: center;
  }
}

.nav-icon-container {
  display: none;
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-link {
  &:hover {
    color: $rekap_teal--x-light;
  }
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;

  .item {
    text-align: center;
    @include text-styles__body(1);
    color: $rekap_white;

    &:hover {
      color: $rekap_teal--x-light;
    }
  }

  .staff-icon-group {
    display: flex;
    align-items: baseline;
  }

  .icon {
    color: $rekap_white;
    font-size: 1.25rem;
  }

  .left {
    padding: 0.25rem 0 0 0.375rem;
  }

  .right {
    padding: 0.25rem 0.375rem 0 0;
  }

  &:hover {
    color: $rekap_teal--x-light;
    .item,
    .icon {
      color: $rekap_teal--x-light;
    }
  }
}

.highlighted {
  color: $rekap_teal--x-light;
  font-weight: 600;
  .item,
  .icon {
    color: $rekap_teal--x-light;
  }
}

.profile-section {
  display: block;
  margin-left: auto;
}

.user-container {
  display: none;
}

.arrow-style {
  left: 85% !important;
}

.logout-icon {
  margin-right: 0.625rem;

  img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.mobile-nav-divider {
  height: 1px;
  background-color: $rekap_grey--light;
}

.mobile-nav-list {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.mobile-nav-list-item {
  padding: 1rem 2rem;
  @include text-styles__body(2);
}

.mobile-menu-icon-container {
  display: flex;
  align-items: center;
}

.drawer-container {
  display: block;
}

@media (min-width: $desktopWidth) {
  .kl-navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
  }

  .kl-navbar {
    margin: 0 3.75rem;
    width: $desktopWidth;
    max-width: none;
    min-width: 0;
  }

  .user-container {
    position: relative;
    display: flex;
    align-items: center;
    transform: translateX(0.25rem);

    .profile-button {
      height: 5rem;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: transparent;
      justify-content: space-between;
      position: relative;
      color: $rekap_white;
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: 0.44px;
      text-align: center;

      .profile-text {
        font-stretch: normal;
        font-style: normal;
        font-family: 'Inter';
        padding-right: 0.65rem;
        text-transform: none;
        font-weight: 400;
        font-size: $remFontSizeS;
        line-height: 1.5rem;
        letter-spacing: 0.15px;
      }

      &:hover,
      &:focus {
        color: $rekap_teal--x-light;
      }
    }
  }

  .logo-container {
    position: relative;
    display: block;
    margin-left: 0;
    .kl-logo-link {
      display: flex;
      align-items: center;
      border-bottom: none;
    }

    .kl-logo {
      height: 2rem;
      width: 100%;
    }
  }

  .mobile-menu-icon-container {
    display: none;
  }

  .nav-icon-container {
    width: 29.625rem;
    height: 3.75rem;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 3.875rem;
    gap: 3.0625rem;
  }

  .drawer-container {
    display: none;
  }
}
