@import '../../styles/variables.scss';

.k-page-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: $smallContentWidth;

  .kph-title {
    color: $rekap_black;
    font-size: 23px;
    font-weight: 400;
    line-height: 28px;
    margin-left: 16px;
  }

  .kph-backbutton {
    width: 32px;
    height: 32px;
    align-self: flex-start;

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $tabletWidth) {
  .k-page-header {
    width: $mediumContentWidth;
  }
}

@media screen and (min-width: $desktopWidth) {
  .k-page-header {
    width: $largeContentWidth;
  }
}
