@import '../../style.scss';

.icon-button {
  box-shadow: none;
  background: none;
  border: none;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
    outline: none;
  }
}
