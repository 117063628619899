@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/skeleton';

.patient-report {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  .patient-report-container {
    width: 100%;
    max-width: $largeContentWidth;
  }
  &__skeleton {
    @include skeleton;
    &--details {
      height: 100vh;
    }
  }
  &__container {
    margin: 1.5rem auto 0;
  }
}

.full-loading {
  width: 100%;
  min-width: none;
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
}

.link-textfield {
  display: flex;
  margin-top: 1.5rem;

  .copied {
    background-color: rgba($rekap_green, 0.5);
    color: $rekap_green;
  }

  .copy-button {
    margin-left: 0.9rem;
    width: max-content;
    border: none;
    border-radius: $borderRadius;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      filter: brightness(0.85);
      transition: all 0.5s;
    }
  }
}

.floating-stepper-container {
  position: sticky;
  top: 9.375rem;

  .share-mobile {
    display: block;
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    transition: all 0.5s;
    z-index: 500;
    background-color: $rekap_teal--dark;
  }

  .floating-stepper {
    .MuiStepLabel-label {
      cursor: pointer;
    }

    .share-report {
      width: 100%;
      display: flex;
      justify-content: left;
      margin-top: 1.25rem;

      .share-button {
        width: 90%;
        background-color: $rekap_teal--dark;
        color: $rekap_white;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        display: flex;
        align-items: center;
        border: none;
        border-radius: $borderRadius;
        justify-content: center;
        transition: all 0.5s;

        p {
          margin-left: 0.31rem;
        }

        &:hover {
          cursor: pointer;
          background-color: rgba($color: $rekap_teal--dark, $alpha: 0.8);
          transition: all 0.5s;
        }
      }

      .postop-button {
        width: 100%;
        background-color: $rekap_white;
        color: $rekap_teal--dark;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        display: flex;
        align-items: center;
        border: solid;
        border-color: $rekap_teal--dark;
        border-width: 1px;
        border-radius: $borderRadius;
        justify-content: center;
        transition: all 0.5s;

        p {
          margin-left: 0.31rem;
          font-weight: 500;
        }

        &:hover {
          cursor: pointer;
          background-color: rgba($color: $rekap_teal--dark, $alpha: 0.8);
          transition: all 0.5s;
          color: $rekap_white;
        }
      }
    }
  }
}

.report-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-info {
  height: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.profile-info-rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  .field {
    margin-right: 24px;
  }
}
.profile-info-rows-fullwidth {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.surgery-info {
  max-width: 1400px;
  margin: 0 auto;

  text-align: center;
  .surgery-title {
    margin-bottom: 17px;
  }
  .surgery-doctor {
    margin: 0;
  }
  .surgery-date {
    margin-bottom: 44px;
  }
}

.report-modal {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 268px;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 24px;
  .modal-title {
    margin-bottom: 20px;
  }
  .modal-body-text {
    margin-bottom: 20px;
  }
}
.modal-button-container {
  display: flex;
  justify-content: space-between;
}
.content-container {
  max-width: $pageMaxWidth;
  margin: 1rem auto 0;
}
.profile-title {
  color: $rekap_black;
  margin-bottom: 20px;
}

.info-row {
  margin-bottom: 15px;
}

.section-header {
  color: $rekap_black;
  text-align: left;
  margin-top: 30px !important;
}

.disclosure {
  padding: 0 1.5rem 0rem 1.5rem;
}

.pdfBgOverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $rekap_black;
  opacity: 0.7;
  z-index: 98;
  top: 0;
}

.export-pdf-text {
  color: $rekap_white;
  position: relative;
  display: flex;
  z-index: 1500;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.saveAsPDFContainer {
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 99;
}

.pdfOpenBtn {
  display: block;
  margin-top: 1rem;

  span {
    font-size: 16px;
  }

  &.report-status {
    margin-top: 0.5rem;
  }
}

.pdfCloseBtn {
  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 101;
}

.displayNone {
  display: none;
}

.share-report {
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: 1.25rem;
}

.text-container {
  line-height: 2rem;
}

.overview-description {
  line-height: 2rem;
}

.error-message {
  width: 250px;
  text-align: center;
  margin: 1rem;
  color: $rekap_red;
}
.unpaid-action-group {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .report-status {
    display: flex;
    max-width: 23.375rem;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .report-status-verbiage {
      .report-status-verbiage-title {
        font-size: $remFontSizeL;
        line-height: 1.75rem;
        padding-bottom: 0.5rem;
        color: $rekap_black;
      }
      .report-status-verbiage-caption {
        font-size: $remFontSizeXS;
        line-height: 1rem;
        color: $rekap_black;
      }
    }
    .report-status-raw-pdf-button {
      margin-top: 1.5rem;

      .btn--secondary:disabled {
        border: 1px solid $rekap_grey;
      }
    }
  }
}

.patient-pay-divider {
  width: 100%;
  max-width: 23.375rem;
  height: 0.0625rem;
  background-color: #e0e0e0;
  margin: 2.5rem auto;
}

.patient-pay-container {
  width: 100%;
  max-width: 23.375rem;
  margin: 0 auto 5.3125rem;
  .patient-pay-carousel-container {
    margin-bottom: 1.8rem;
    .patient-pay-carousel-title {
      font-size: 1.4375rem;
      line-height: 1.75rem;
      color: #404e92;
      max-width: 21.5625rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }

  .patient-pay-plans-container {
    width: 100%;

    .patient-pay-plans-title {
      font-size: 1.4375rem;
      line-height: 1.75rem;
      color: #313d4e;
      margin-bottom: 1rem;
    }
    .patient-pay-plans-inner-container {
      display: flex;
      flex-wrap: wrap;
      .patient-pay-plan {
        @include border($rekap_grey--x-dark);
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

        .patient-pay-plan-title {
          color: #ffffff;
          font-size: 1.4375rem;
          line-height: 1.75rem;
          text-align: center;
          padding: 0.625rem 0;
          height: 3rem;
        }
        .free {
          background-color: $rekap_grey--x-dark;
        }
        .paid {
          background-color: #2b5a9e;
        }

        .patient-pay-plan-cta {
          margin: 2.5rem auto 2.9375rem;
          .patient-pay-plan-cta-title {
            text-align: center;
            font-size: 3.625rem;
            line-height: 4.25rem;
            font-weight: bold;
            margin-bottom: 1rem;
          }
        }

        .patient-pay-plan-items {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          .patient-pay-plan-item {
            flex-direction: row;
            display: flex;
            margin: 0.25rem 2.375rem;
            align-items: center;
            .patient-pay-plan-icon {
              margin-right: 0.9375rem;
            }
          }
          .not-included {
            color: #b4bbc3;
          }
        }
        .bottom-spacer {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 90%;

  .modal-text {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .confirm-button-container {
    width: 100%;
  }
}

@media screen and (min-width: $tabletWidth) {
  .unpaid-action-group {
    .report-status {
      max-width: 33.875rem;
      margin: 0 auto;

      .report-status-verbiage {
        .report-status-verbiage-title {
          font-size: $remFontSizeXL;
          line-height: 2.25rem;
          padding-bottom: 1rem;
        }
        .report-status-verbiage-caption {
          font-size: $remFontSizeM;
          line-height: 1.5rem;
          letter-spacing: 0.0094rem;
        }
      }
    }
  }

  .patient-pay-divider {
    max-width: 45.5rem;
  }

  .patient-pay-container {
    width: 100%;
    max-width: 45.5rem;
    .patient-pay-carousel-container {
      .patient-pay-carousel-title {
        font-size: 1.75rem;
        line-height: 2.25rem;
        max-width: initial;
        font-weight: 600;
      }
    }

    .patient-pay-plans-container {
      width: 100%;
      margin: 0;
      .patient-pay-plans-title {
        font-size: 1.4375rem;
        line-height: 1.75rem;
        color: #313d4e;
        margin-bottom: 1rem;
      }
      .patient-pay-plans-inner-container {
        flex-wrap: nowrap;
        width: 100%;
        justify-content: space-between;

        .patient-pay-plan {
          max-width: 22.25rem;
          .patient-pay-plan-title {
            height: 2.75rem;
          }
          .patient-pay-plan-cta {
            margin: 2.5rem 2.5rem 2.1875rem;
            .patient-pay-plan-cta-title {
              text-align: left;
            }
          }
          .patient-pay-plan-items {
            .patient-pay-plan-item {
              margin: 0.25rem 1.8rem 0.25rem 2.5rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $desktopWidth) {
  .unpaid-action-group {
    .report-status {
      display: flex;
      max-width: 55.875rem;
      width: 100%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .report-status-verbiage {
        width: 36.75rem;
        .report-status-verbiage-title {
          font-size: $remFontSizeXXL;
          line-height: 2.5rem;
          letter-spacing: 0.0156rem;
          padding-bottom: 1rem;
        }
        .report-status-verbiage-caption {
          font-size: $remFontSizeM;
          line-height: 1.5rem;
          letter-spacing: 0.0094rem;
        }
      }
    }
  }

  .patient-pay-divider {
    max-width: 75rem;
    margin: 5rem auto;
  }

  .patient-pay-container {
    width: 100%;
    max-width: 75rem;
    .patient-pay-carousel-container {
      margin-bottom: 6.6rem;
      .patient-pay-carousel-title {
        font-size: 2.0625rem;
        line-height: 2.5rem;
        max-width: initial;
        font-weight: 600;
        letter-spacing: 0.25px;
      }
      .patient-pay-carousel {
        height: 40rem;
      }
    }

    .patient-pay-plans-container {
      width: 100%;
      margin: 0;
      .patient-pay-plans-title {
        font-size: 2.0625rem;
        letter-spacing: 0.25px;
        line-height: 2.5rem;
        color: #313d4e;
        margin-bottom: 1rem;
      }
      .patient-pay-plans-inner-container {
        flex-wrap: nowrap;
        width: 100%;
        justify-content: initial;

        .patient-pay-plan {
          margin-right: 1.5625rem;
          min-width: 24rem;
          .patient-pay-plan-title {
            height: 2.75rem;
          }
          .patient-pay-plan-cta {
            margin: 2.5rem 2.375rem 2.2rem;
            .patient-pay-plan-cta-title {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.external-container {
  .external-top-margin {
    top: -10px;
  }
}

.tall-datepicker {
  margin: 0.25rem 0 0 0;

  .SingleDatePickerInput__withBorder {
    height: 2.875rem;
    width: 21.375rem;
  }
}
