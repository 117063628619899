@import '../../styles/variables.scss';

.checkbox-outer-container {
  .checkbox-parent {
    display: block;
    margin: 0.75rem 0 0.5rem 0;
  }
  .checkbox-child {
    padding: 0.5rem 0 0.5rem 3rem;
  }
}
