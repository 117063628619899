@import '../../styles/variables.scss';

@mixin kalibertag {
  border-radius: 3.75rem;
  padding: 0.5rem 0.75rem;
  font-family: 'Inter';
  width: auto;
  font-weight: bold;
  font-size: $remFontSizeXXS;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;

  .kt-icon {
    display: flex;
    align-items: center;
    margin-left: 0.563rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.kaliber-tag {
  &__content {
    overflow: hidden;
  }

  &--default {
    @include kalibertag;
    background-color: $rekap_grey--x-light;
    color: $rekap_grey--x-dark;
  }

  &--red {
    @include kalibertag;
    background-color: $rekap_red--x-light;
    color: $rekap_red--dark;
  }

  &--yellow {
    @include kalibertag;
    background-color: $rekap_yellow--light;
    color: $rekap_brown;
  }

  &--green {
    @include kalibertag;
    background-color: $rekap_green--light;
    color: $rekap_green;
  }

  &--primary {
    @include kalibertag;
    background-color: $rekap_teal--xx-light;
    color: $rekap_teal;
  }

  &--black {
    @include kalibertag;
    background-color: $rekap_black;
    color: $rekap_white;
  }

  &--disabled {
    @include kalibertag;
    background-color: $rekap_grey;
    color: $rekap_grey--dark;

    .kt-icon {
      &:hover {
        cursor: default;
      }
    }
  }
}
