@import '../../styles/mixins/typography';
@import '../../styles/variables.scss';

.footer {
  background-color: $rekap_blue--dark;
  margin: 2rem 0 0 0;
  padding: 1.875rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  &__links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $rekap_white;
    gap: 1rem;
  }
  &__link {
    @include text-styles__button(2);
    width: fit-content;
  }
  &__logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  &__logo {
    width: 7.25rem;
    height: 1.3125rem;
  }
  &__copyright {
    @include text-styles__body(3);
    color: $rekap_white;
  }
}

@media (min-width: $tabletWidth) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    margin: 2.5rem 0 0 0;

    &__links-container {
      justify-content: center;
      align-items: start;
      gap: 0.75rem;
    }

    &__logo-container {
      justify-content: center;
      align-items: end;
    }

    &__copyright {
      @include text-styles__body(2);
      color: $rekap_white;
    }
  }
}
@media (min-width: $desktopWidth) {
  .footer {
    background-color: $rekap_blue--dark;
    padding: 3.125rem 4.25rem;

    &__link {
      @include text-styles__button(1);
      width: fit-content;
    }
    &__logo {
      width: 13.5rem;
      height: 2.5rem;
    }
    &__copyright {
      @include text-styles__body(1);
      color: $rekap_white;
    }
  }
}
