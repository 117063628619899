@import '../../styles/variables.scss';
@import '../../styles/mixins/_skeleton.scss';

.surgery-page-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: $smallContentWidth;

  .k-page-header {
    margin-top: 0.5rem;
    margin-bottom: 0;
    width: 100%;
  }
}
.surgery-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2.625rem auto 0;
    width: $smallContentWidth;
  }

  &__skeleton {
    @include skeleton;
    &--header {
      height: 1.75rem;
      width: 26.875rem;
    }
    &--switch {
      height: 2.5rem;
    }
    &--content {
      height: 100vh;
    }
  }
}

@media (min-width: $tabletWidth) {
  .surgery-page {
    &__container {
      gap: 1.75rem;
      width: $mediumContentWidth;
    }
  }
}

@media (min-width: $desktopWidth) {
  .surgery-page {
    &__container {
      width: $largeContentWidth;
      margin-top: 42px;
      gap: 2rem;
    }
  }
}
