@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.multiselect-text {
  margin-top: 0.38rem;
  font-size: 17px;
  margin-bottom: 1rem;
}
.input-container {
  display: flex;
  flex-direction: column;
  min-width: 12rem;
  position: relative;
  .input-title {
    font-weight: 500;
    font-size: 0.75rem;
  }

  .select-container {
    @include border($rekap_grey--light);
    position: absolute;
    background-color: $rekap_white;
    display: flex;
    flex-direction: column;
    width: 12rem;

    .select-item {
      @include border($rekap_grey--light);
    }
  }
}

.label {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 16px;
  color: $rekap_grey--x-dark;
  font-family: Inter, Source Sans Pro, Roboto, -apple-system, BlinkMacSystemFont;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: $modalZIndex - 1;
  left: 0;
  top: 0;
}
.wrapper {
  position: relative;
  min-width: 12rem;

  .input-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include border($rekap_grey--light);
    border-radius: $borderRadius;
    background-color: $rekap_white;
    align-items: center;
    height: 41px;
    max-width: 20rem;

    &:focus-within {
      @include border($rekap_grey--x-dark);
    }

    input:focus-within,
    input:focus-visible {
      border: none;
      outline: none;
    }
    .header {
      border: none;
      font-size: $remFontSizeS;
      white-space: nowrap;
      padding: 0.87rem;
    }
  }
  .icon {
    padding: 0.2rem 0;
    border-left: 1px solid $rekap_grey--light;
    height: 75%;
    display: flex;
    align-items: center;
    color: $rekap_grey--x-dark;
  }
  .tag-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: $modalZIndex - 1;

    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $rekap_grey--x-light;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $rekap_grey;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $rekap_grey--dark;
    }

    .tag {
      margin: 0.5rem 0.25rem;
      padding: 0.25rem 0.2rem 0.25rem 0.6rem;
      font-size: 0.7rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: $rekap_grey--x-light;
      border-radius: 500px;
      width: auto;
      display: flex;
      align-items: center;
      color: $rekap_black;

      &:hover {
        background-color: $rekap_grey--light;
        color: $rekap_black;
      }

      .clear-icon {
        color: $rekap_black;
        margin-left: 0.25rem;
        font-size: 0.85rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.header-title {
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
}
.list {
  position: absolute;
  z-index: $modalZIndex;
  background-color: $rekap_white;
  width: 100%;
  @include border($rekap_white);
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  max-height: 20rem;
  overflow: scroll;
  text-align: left;
  -webkit-overflow-scrolling: touch;

  .scroll-list {
    .list-item {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      padding: 0.5rem 0.6rem;
      font-size: $remFontSizeS;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        background-color: $rekap_cream;
        color: $rekap_black;
      }

      .add-option-input {
        border: none;
        font-size: $remFontSizeS;
        white-space: nowrap;
        background-color: transparent;
        outline: none;
        width: 80%;

        &:focus {
          white-space: nowrap;
          text-overflow: ellipsis;
          background-color: $rekap_cream;
          outline: none;
        }
      }
    }

    .item-selected {
      background-color: $rekap_grey--x-light;
      color: $rekap_black;
      outline: none;

      &:hover {
        background-color: $rekap_grey--light;
        color: $rekap_black;
      }
    }
    .add-option {
      background-color: $rekap_white;
      overflow: auto;
      display: flex;
    }
  }
}

// Note: Necessary Overrides for Dropdown
.wrapper {
  button {
    overflow: visible;
    width: inherit;
    margin: inherit;
    padding: inherit;
    border: none;
    background: inherit;
    font: inherit;
    line-height: normal;
    color: inherit;
    text-align: inherit;
    -webkit-appearance: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  p {
    margin: 0;
    padding: 0;
  }
}
