@import '../../../../styles/variables.scss';

#container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: space-between;
  .left-container {
    flex-direction: row;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .right-container {
    margin-left: auto;
  }
}

.filter {
  margin-right: 24px;
}

.filter-title {
  font-size: 0.75rem !important;
  margin-bottom: 0.07rem !important;
}
.filter-select {
  height: 54px;
}

input[type='search']::-webkit-search-cancel-button {
  position: relative;
  right: 15px;
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  margin-left: 0.4em;
  background: $rekap_grey--light;
  clip-path: polygon(
    20% 0%,
    0% 20%,
    30% 50%,
    0% 80%,
    20% 100%,
    50% 70%,
    80% 100%,
    100% 80%,
    70% 50%,
    100% 20%,
    80% 0%,
    50% 30%
  );
  cursor: pointer;
}
