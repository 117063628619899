@import '../../../../styles/variables.scss';

.fab {
  display: flex;
  position: fixed;
  background-color: $rekap_teal;
  bottom: 1rem;
  right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    height: 2rem;
    width: 2rem;
  }
}

@media (min-width: $tabletWidth) {
  .fab {
    display: none;
  }
}
