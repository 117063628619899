@import '../../styles/variables.scss';

.checkbox-outer-container {
  .checkbox-header {
    display: block;
    font-weight: bold;
  }
  .checkbox-title {
    display: inline-block;
    margin-bottom: 0.25rem;
  }
  .checkbox-container {
    display: flex;
    margin-top: 0.5rem;
  }
  .row {
    flex-direction: row;
  }
  .column {
    flex-direction: column;
    gap: 1rem;
  }
}
