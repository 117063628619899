@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.patient-header {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 0.75rem;
  padding: 0 1rem 1rem;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
  }
  &__label {
    @include text-styles__body(2, 'normal', $rekap_grey--x-dark);
  }

  &__data {
    @include text-styles__body(1, 'semibold');
  }
}

@media (min-width: $tabletWidth) {
  .patient-header {
    flex-direction: row;
    gap: 1.5rem;

    &__item {
      width: 12.5rem;
    }
  }
}

@media (min-width: $desktopWidth) {
  .patient-header {
    margin-top: 0;
    padding: 0 2.4rem;
  }
}
