@import '../../../../../../../../styles/variables.scss';
@import '../../../../../../../../styles/mixins/_typography.scss';

.acl-reconstruction-repair {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 0.5rem;

  .ktf-container {
    width: 100%;

    .ktf-input-text {
      padding: 0.5rem;
      height: 2.5rem;
    }
  }

  .acl-segment {
    .segmented-control__label {
      @include text-styles__body(1);
    }
  }

  .additional-details {
    .ktf-container {
      gap: 0.25rem;

      .ktf-text-container {
        .ktf-label {
          margin: 0;
          height: auto;
          @include text-styles__body(1);
        }
      }

      .ktf-textarea-container {
        padding: 0;
      }
    }
  }
}

@media (min-width: $tabletWidth) {
  .acl-reconstruction-repair {
    .ktf-container .ktf-input-text {
      height: 1.5rem;
      padding: 0.75rem 0.5rem;
    }

    .acl-segment {
      .segmented-control {
        &__segment {
          width: 7.375rem;
          padding: 0;
        }
      }
    }
  }
}
