@import '../../styles/variables.scss';

.checkbox-label {
  display: flex;
  align-items: center;
}
.checkbox-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0.1rem;
  width: $remFontSizeS;
  height: $remFontSizeS;
  outline: 0.15rem solid $rekap_grey--dark;
  margin: 0 $remFontSizeS;
  position: relative;
  cursor: pointer;
}
.checkbox-input:checked {
  background-color: $rekap_grey--x-dark;
}
.checkbox-input:checked::after {
  content: '\2713';
  color: $rekap_white;
  font-size: $remFontSizeS;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkbox-input:disabled {
  outline: 0.2rem solid $rekap_grey--light;
  background-color: $rekap_grey--light;
}
