@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.rb-outer-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .rb-custom-container,
  .rb-default-container {
    display: flex;
    flex-direction: row;

    .rb-container {
      box-shadow: none;
      background-color: transparent;
      padding: 0;
      display: flex;
      width: 100%;
      height: 64px;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;

      :hover {
        box-shadow: none;
      }
    }
  }

  .rb-custom-container {
    .rb-container {
      border-bottom: 1px solid $rekap_grey--light;
      border-left: 1px solid $rekap_grey--light;
      border-right: 1px solid $rekap_grey--light;
      border-radius: 0 0 6px 6px;
    }
  }

  .rb-default-container {
    .rb-container {
      @include border($rekap_grey--light);
      border-radius: 6px 6px 0 0;
    }
  }

  .btn__text {
    display: flex;
    width: 15rem;
    justify-content: center;
    align-items: center;

    .rb-icon-container {
      margin: 0 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .rb-icon {
        width: 33px;
        height: auto;
      }

      .rb-icon-pdf {
        path {
          stroke: $rekap_grey--x-dark;
        }
      }
    }
  }

  .rb-pop-over-icon-container {
    position: absolute;
    z-index: $modalZIndex;
    right: 8px;
    top: 8px;

    .rb-pop-over-icon-container-inner {
      position: relative;
      display: flex;
      align-self: flex-end;
      background-color: $rekap_white;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      padding: 7px 10px;
      .rb-icon {
        width: 22px;
        height: auto;
        margin: 5px;
      }
      &:hover {
        box-shadow: $boxShadow;
      }
      .rb-eye-icon {
        fill: $rekap_grey--x-dark;
        padding: 0;
      }
      .rb-delete-icon {
        fill: $rekap_red;
      }
    }
  }

  .rb-text-container {
    font-style: italic;
    margin-right: 10px;
    overflow: hidden;

    .rb-upload-text {
      font-size: 14px;
    }

    .rb-file-text {
      font-size: 14px;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rb-custom-file-text {
      font-size: 14px;
      max-width: 125px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .rb-file-size-text {
      font-size: 12px;
      color: $rekap_grey;
    }
  }

  .rb-disabled {
    color: $rekap_grey--x-dark;
    // not sure if this is the best way to do this but doing it for now
    .rb-file-size-text {
      color: $rekap_grey--light;
    }
  }

  .rb-selected {
    color: $rekap_teal--light;
  }
}
