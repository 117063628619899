@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/_typography';

.patient-header-detail {
  padding: 0.25rem 1rem;

  .patient-header-detail-entry {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @include text-styles__title(3, 'normal', $rekap_grey--x-dark);

    .patient-header-detail-content {
      margin-top: 0.25rem;
      @include text-styles__body(1, 'medium', $rekap_black);

      &--no-entry {
        @include text-styles__body(1, 'normal', $rekap_grey--x-dark, 'italic');
      }
    }
  }
}

@media (min-width: $tabletWidth) {
  .patient-header-detail {
    display: flex;
    gap: 1.5rem;

    .patient-header-detail-content {
      min-width: 12.5rem;
    }
  }
}
