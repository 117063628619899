@import '../../styles/variables.scss';

.full-loading {
  width: 100%;
  min-width: none;
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: $modalZIndex - 1;
  left: 0;
  top: 0;
}

.floating-stepper-container {
  position: sticky;
  top: 9.375rem;

  .share-mobile {
    display: block;
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    transition: all 0.5s;
    z-index: 500;
    background-color: $rekap_teal--light;
  }

  .floating-stepper {
    .MuiStepLabel-label {
      cursor: pointer;
    }

    .share-report {
      width: 100%;
      display: flex;
      justify-content: left;
      margin-top: 1.25rem;
    }
  }
}

.profile-info {
  height: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.profile-info-rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  .field {
    margin-right: 24px;
  }
}
.profile-info-rows-fullwidth {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.surgery-info {
  max-width: 1400px;
  margin: 0 auto;

  text-align: center;
  .surgery-title {
    margin-bottom: 17px;
  }
  .surgery-doctor {
    margin: 0;
  }
  .surgery-date {
    margin-bottom: 44px;
  }
}

.external-report-modal {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 17rem;
  min-height: 10rem;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  gap: 1rem;
  z-index: 1700;

  .external-report-modal-title {
    font-size: $remFontSizeM;
    font-weight: 500;
  }

  .modal-button-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.content-container {
  max-width: $pageMaxWidth;
  margin: 1rem auto 0;
}
.profile-title {
  color: $rekap_black;
  margin-bottom: 20px;
}

.info-row {
  margin-bottom: 15px;
}

.section-header {
  color: $rekap_black;

  text-align: left;
  margin-top: 30px !important;
}

.disclosure {
  padding: 0 1.5rem 0rem 1.5rem;
}

.spinner-container {
  height: 3.5rem;
}

.error-message-container {
  color: $rekap_red;
}

@media (min-width: $desktopWidth) {
  .external-report-modal {
    width: 42.5rem;
    height: 10.75rem;
  }
}
