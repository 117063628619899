@import '../../styles/variables.scss';
.search-box-container {
  display: flex;
  align-items: center;
  width: 303px;
  height: 46px;
  background: $rekap_white;
  border-radius: $borderRadius;
  border-style: solid;
  border-color: $rekap_grey--light;
  border-width: 1px;
  padding-left: 8px;
  .search-icon {
    font-size: 18px;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $rekap_grey;
  }
  .search-box {
    font-size: 16px;
    line-height: 24px;
    height: 100%;
    width: 100%;
    background: transparent;
    color: $rekap_black;
    border: none;
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $rekap_grey;
    }
  }

  &:focus-within,
  &:active {
    outline: none;
    border-color: $rekap_grey--x-dark;
  }
}
