@import '../../styles/variables.scss';
.add-facility-container {
  width: $smallContentWidth;
  margin: auto;

  .k-page-header {
    margin-bottom: 1.125rem;
  }
  .button-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 25px;

    .single-button {
      width: 11.25rem;
    }
  }

  .ktf-container,
  .ks-container,
  .kpf-container {
    margin-bottom: 16px;
  }
}

@media (min-width: $tabletWidth) {
  .add-facility-container {
    width: $mediumContentWidth;

    .k-page-header {
      margin-bottom: 1.75rem;
    }
  }
}

@media (min-width: $desktopWidth) {
  .add-facility-container {
    width: $largeContentWidth;

    .k-page-header {
      margin-bottom: 2.125rem;
    }
  }
}
