@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.optional-value {
  @include text-styles__body(1, 'semibold');
  &--no-entry {
    display: block;
    margin-top: 0.25rem;
    @include text-styles__body(1, 'normal', $rekap_grey--x-dark, 'italic');
  }
}
