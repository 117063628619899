@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.add-surgery-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .kaliber-card {
    gap: 1.25rem;
  }

  .add-surgery-container {
    width: 23.375rem;

    .k-page-header {
      margin: 1.5rem 0;
    }

    .sdf-container .asp-form-inputs {
      margin-top: 0.25rem;
    }

    .patient-add-details-container .ks-input-container,
    .sdf-container .ks-input-container {
      width: 20.375rem;
    }

    .asp-custom-input-modal-text {
      height: 3rem;
      font-family: Inter;
      font-size: $remFontSizeS;
      line-height: 1.5;
      letter-spacing: 0.15px;
      text-align: left;
      color: $rekap-black;
      margin-top: 1.625rem;
    }
    .asp-custom-input-modal-input-container {
      margin: 0;
      background-color: $rekap_white;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5rem;
      margin-top: $remFontSizeS;
      width: 100%;
      height: 2.5rem;
      @include border($rekap_grey--light);
      border-radius: 0.25rem;
      &:focus-within {
        @include border($rekap_grey--x-dark);
      }
      input:focus-within,
      input:focus-visible {
        border: none;
        outline: none;
      }
      .asp-custom-input-modal-input-text {
        border: none;
        outline: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: $remFontSizeS;
        line-height: 1.5rem;
        width: 100%;
        text-overflow: ellipsis;
        letter-spacing: 0.15px;
        color: $rekap-black;
        &:focus-within {
          border: none;
          outline: none;
        }
      }
    }
    .asp-custom-input-modal-buttons-container {
      height: 2.5rem;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: 1.5rem;
      gap: $remFontSizeS;
      padding: 0;
    }
    .asp-card-container {
      display: flex;
      flex-direction: column;

      .asp-form-inputs {
        .asp-name-fields {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .asp-patient-details {
          .asp-zip-container {
            .k-text-field__label-container {
              line-height: 0.875rem;
            }
            width: 100%;
          }
        }
      }

      .SingleDatePickerInput__withBorder {
        height: 46px;
      }

      .asp-form-inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &.has-name-error {
          gap: 0.75rem;
        }
      }

      .asp-patient-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .asp-patient-details-subcontainer {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          .asp-mobile-phone-field {
            margin-top: 0.25rem;
            .kpf-container .kpf-input-container {
              height: 3rem;
            }
          }

          .asp-email-field {
            .k-text-field__input-container {
              height: 3rem;
            }
          }

          .asp-top-margin-field {
            margin-top: 0.25rem;
          }
        }

        .asp-locality-container {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 1rem;

          @media screen and (min-width: $tabletWidth) {
            > :nth-child(1) {
              flex: 0 0 21.75rem;
            }
            > :nth-child(2) {
              flex: 0 0 7rem;
            }
            > :nth-child(3) {
              flex: 0 0 10.75rem;
            }
          }

          @media screen and (min-width: $desktopWidth) {
            > :nth-child(1) {
              flex: 0 0 21.75rem;
            }
            > :nth-child(2) {
              flex: 0 0 7rem;
            }
            > :nth-child(3) {
              flex: 0 0 9.75rem;
            }
          }
        }
      }

      .asp-surgery-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .asp-datepicker-size {
        width: 100%;
        max-width: 21.75rem;

        .SingleDatePickerInput__withBorder {
          width: 20.375rem;
          height: 3rem;
          box-sizing: border-box;
        }

        @media (min-width: $tabletWidth) {
          .SingleDatePickerInput__withBorder {
            width: 21.75rem;
          }
        }
      }
    }

    .asp-patient-region-procedure-details-container {
      @include border($rekap_grey--light);
      border-radius: 0.5rem;
      padding: $remFontSizeS;
      .asp-patient-region-line-divider {
        border-top: 1px solid $rekap_grey--light;
        margin: $remFontSizeS 0;
      }
      .asp-patient-region-label {
        font-size: $remFontSizeS;
        font-weight: 500;
        line-height: 1.5;
        height: 1.5rem;
        color: $rekap-black;
        margin-bottom: 0.5rem;
      }
      .asp-surgery-side-container {
        .asp-surgery-side-label {
          width: 100%;
          height: 1rem;
          margin: 0.5rem 0 0.25rem 0;
          @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
        }
      }
    }
    .asp-add-custom-entry-button {
      width: 11.75rem;
      height: 2.25rem;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: -0.5rem;
      font-weight: 600;
      font-size: $remFontSizeS;
      line-height: 1.25;
      color: #008383;
      display: flex;
      gap: 0.5rem;
      cursor: pointer;
    }
  }

  .ascp-disclaimer {
    @include text-styles__body(3, 'normal', $rekap_grey--dark, 'italic');
    margin-top: 3.5rem;
    .ascp-paragraph {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .asp-custom-procedure {
    margin-bottom: 0;
  }

  .asp-custom-procedures-container {
    .custom-procedure:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.error-message {
  width: 250px;
  text-align: center;
  margin: 1rem;
  color: $rekap_red;
}
.success-message {
  width: 250px;
  text-align: center;
  margin: 1rem;
  color: $rekap_green;
}

@media screen and (min-width: $tabletWidth) {
  .add-surgery-page-container {
    .add-surgery-container {
      width: $mediumContentWidth;

      .asp-card-container {
        .asp-patient-details {
          .asp-locality-container {
            gap: 1.5rem;
          }
        }
      }

      .ascp-disclaimer {
        margin-top: 3.75rem;
      }

      .asp-card-container {
        .asp-form-inputs {
          .asp-name-fields {
            display: flex;
            flex-direction: row;
            gap: 1rem;
          }
        }

        .asp-form-inputs
          .asp-patient-details
          .asp-zip-container
          .k-text-field__label-container {
          line-height: 1.25rem;
        }

        .asp-patient-details {
          .asp-patient-details-subcontainer {
            .asp-email-field .k-text-field__input-container,
            .asp-mobile-phone-field .kpf-container .kpf-input-container {
              height: 3rem;
            }
          }
        }
      }

      .asp-name-fields,
      .asp-name-fields .first-name,
      .asp-name-fields .middle-name,
      .asp-name-fields .last-name {
        display: flex;
        gap: 1rem;
        align-items: flex-start;
        height: 4.5rem;

        .k-text-field {
          width: 13.5rem;
        }

        &.has-name-error {
          height: 6rem;
        }

        .middle-name,
        .last-name {
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;
          .k-text-field__label-container {
            height: 1.25rem;
            visibility: hidden;
          }
        }
      }

      .ks-container .ks-text-container .ks-label {
        margin-top: 0.25rem;
      }

      .patient-add-details-container .ks-container,
      .sdf-container .ks-container {
        max-width: 21.75rem;
      }

      .patient-add-details-container .ks-input-container,
      .sdf-container .ks-input-container {
        width: 21.75rem;
      }

      :not(.asp-address-container, .asp-zip-container) > .k-text-field {
        width: 21.75rem;
      }

      .button-container {
        width: 11.25rem;
      }

      .kaliber-card {
        padding: 1rem 1.5rem;
        margin-bottom: 1.5rem;
      }

      .sdf-container .kaliber-card {
        gap: 1.25rem;

        .ks-label {
          margin-top: 0;
        }
      }

      .asp-button-container .asp-inner-button-container .btn--primary {
        width: 10.625rem;
      }

      .asp-card-container .asp-patient-details {
        gap: 0.75rem;
      }
    }
  }
}

@media screen and (min-width: $desktopWidth) {
  .add-surgery-page-container {
    .add-surgery-container {
      width: $largeContentWidth;
      .kaliber-card {
        padding: 1.5rem 2rem;
        margin-bottom: 2rem;
      }

      .asp-patient-details
        .asp-patient-details-subcontainer
        .asp-locality-container
        .ks-label {
        margin-top: 0.25rem;
      }

      .asp-card-container .asp-form-inputs .asp-name-fields {
        display: flex;
        gap: 1.5rem;
        align-items: flex-start;
        height: 4.5rem;

        &.has-name-error {
          height: 6rem;
        }

        .middle-name,
        .last-name {
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;
          .k-text-field__label-container {
            height: 1.25rem;
            visibility: hidden;
          }
        }
      }

      .asp-name-fields
        :not(.asp-address-container, .asp-zip-container)
        > .k-text-field {
        width: 21.75rem;
      }
    }
  }
}
