@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.knf-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  margin: 0.5rem 0;
  .knf-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .knf-label {
      margin: 0 0 0 3px;
      width: 100%;
      height: 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $rekap_grey--x-dark;
    }
  }

  .knf-input-container {
    margin: 0;
    background-color: $rekap_white;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    width: 100%;
    height: 40px;
    @include border($rekap_grey--light);
    border-radius: $borderRadius;

    &:focus-within {
      @include border($rekap_grey--x-dark);
    }

    input:focus-within,
    input:focus-visible {
      border: none;
      outline: none;
    }
    input::placeholder {
      color: #697688;
    }

    .knf-input-text {
      border: none;
      outline: none;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      text-overflow: ellipsis;
      letter-spacing: 0.5px;

      &:focus-within {
        border: none;
        outline: none;
      }
    }
    .knf-input-error-border {
      border-color: $rekap_red;
    }
  }

  .knf-input-error {
    color: $rekap_red;
    font-size: 12px;
    line-height: 16px;
  }
}
