@import '../../styles/variables.scss';
@import '../../styles/mixins/typography';

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-card {
  min-height: 9.5rem;
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-top: 3.75rem;
  background: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  justify-content: space-evenly;
  align-items: center;
  padding: 2.5rem;
  gap: 1.5rem;

  &__body {
    @include text-styles__title(1);
  }

  &__title {
    font-size: 1.1875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.0094rem;
    text-align: left;
    color: $rekap_black;
  }

  .error-page-button {
    .btn--primary {
      margin: 0 3.594rem;
      width: 11.25rem;
    }
  }
}

.error-card__title--large {
  font-size: $remFontSizeXXXXXL;
}

@media (min-width: $tabletWidth) {
  .error-card {
    //Zeplin wants the width to be 768px.
    // We set the width as 768 minus the padding (40) and margin (20)
    width: 40.5rem;
    margin-top: 12.5rem;
  }
}

@media (min-width: $desktopWidth) {
  .global-container {
    padding-top: 5rem;
  }

  .error-card {
    width: 44.5rem;
    height: 12.5rem;
    padding: 2.5rem;
    justify-content: space-between;

    &__body {
      margin-top: 0;
    }

    &__title {
      margin-bottom: 0;
    }

    .error-page-button {
      .btn--primary {
        width: 11.25rem;
      }
    }
  }
}
