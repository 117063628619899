@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.edit-modal-container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: $modalZIndex;
  justify-content: center;
  align-items: center;

  .edit-modal-content-container {
    width: 20rem;
    max-height: 95%;
    overflow: scroll;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: $rekap_white;
      border-radius: $borderRadius--large;
      padding: 1.5rem;

      .edit-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__title {
          @include text-styles__title(1, 'medium');
        }

        &__close-icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.edit-modal-content-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.show-modal {
  display: flex;
}

@media (min-width: $tabletWidth) {
  .edit-modal-container {
    .edit-modal-content-container {
      width: 45.5rem;
      overflow: auto;

      &__content {
        padding: 1.5rem 2rem;
        .edit-modal-header {
          &__title {
            @include text-styles__headline(3);
          }

          &__close-icon {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }
}
