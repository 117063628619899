@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/border';
@import '../../../../styles/mixins/typography';

.patient-contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  &__text {
    width: 100%;
    @include text-styles__body(1, 'medium', $rekap_black);
    &--disabled {
      @include text-styles__body(1, 'medium', $rekap_grey--x-dark, 'italic');
    }
  }
}

.edit-patient-contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .email-text-field {
    .helper-text {
      width: 17rem;
      margin-top: 0.25rem;
      @include text-styles__body(3, 'normal', $rekap_grey--x-dark, 'italic');

      &__email-address {
        color: $rekap_teal;
        border: 2px solid transparent;

        &:hover {
          cursor: pointer;
          border-bottom-color: $rekap_teal;
        }
      }
    }
  }

  .k-text-field__label-container {
    height: 1rem;
    margin-bottom: 0.25rem;
    display: flex; // So that the k-text-field__label-text is centered in the middle
  }

  .locality-information-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.edit-modal__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: $tabletWidth) {
  .edit-modal__buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;

    .btn--primary {
      width: 10rem;
    }
  }

  .edit-patient-contact-form {
    .email-text-field {
      width: 21.75rem;

      .helper-text {
        width: 41.5rem;
      }
    }

    .locality-information-container {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;

      > :nth-child(1) {
        flex: 0 0 21.75rem;
      }
      > :nth-child(2) {
        flex: 0 0 7rem;
      }
      > :nth-child(3) {
        flex: 0 0 9.75rem;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .edit-modal__buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
  }
  .patient-contact-container {
    max-width: 36.75rem;
    width: 100%;
  }
}
