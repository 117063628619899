@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.custom-procedure {
  &__add-button-wrapper {
    padding: 0.375rem 0.5rem;
    display: inline-flex;
  }

  &__add-button {
    color: $rekap_teal;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    @include text-styles__button(1);
  }

  &__modal-container {
    & .medium {
      width: 17rem;
    }

    .custom-procedure__modal-text {
      margin-top: 1.5rem;
      @include text-styles__body(1);
    }

    .custom-procedure__modal-input-container {
      display: flex;
      box-sizing: border-box;

      width: 100%;
      @include border($rekap_grey--light);
      border-radius: 0.25rem;
      height: 9.375rem;
      padding: 0.375rem;
      margin-top: 1rem;

      &:focus-within {
        @include border($rekap_grey--x-dark);
      }

      .custom-procedure__modal-input-text {
        border: none;
        @include text-styles__body(1);
        width: 100%;
        resize: none;

        &:focus-within {
          border: none;
          outline: none;
        }
      }
    }

    .custom-procedure__modal-buttons {
      align-self: stretch;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      flex-direction: column-reverse;

      .btn--cancel {
        box-sizing: border-box;
        height: 2rem;
        @include text-styles__button(2);
      }

      .btn--primary {
        margin-top: 1.5rem;
        height: 2rem;
        @include text-styles__button(2);
      }
    }

    .kaliber-modal-container {
      .kaliber-modal-content-container {
        .kaliber-modal-title-icon-container-left {
          height: 1.75rem;
          align-items: flex-start;
        }
      }
    }
  }
}

@media only screen and (min-width: $tabletWidth) {
  .custom-procedure {
    &__modal-container {
      & .medium {
        width: 42.5rem;
        height: 14.5rem;
        padding: 1.5rem;
      }

      .custom-procedure__modal-text {
        margin-top: 1.625rem;
      }

      .custom-procedure__modal-input-container {
        align-items: center;
        padding: 0.375rem;
        height: 3rem;
      }

      .custom-procedure__modal-buttons {
        flex-direction: row;
        margin-top: 1.5rem;

        .btn--cancel {
          margin-top: 0;
          height: 2.5rem;
          width: 6.5rem;
          @include text-styles__button(1);
        }

        .btn--primary {
          margin-top: 0;
          height: 2.5rem;
          width: 11.25rem;
          @include text-styles__button(1);
        }
      }

      .kaliber-modal-container {
        .kaliber-modal-close-icon {
          top: 1.375rem;
          right: 1.5rem;
        }
      }
    }
  }
}
