@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins/_typography.scss';

.review-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .messages {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__reminder {
      @include text-styles__body(1, 'medium', 'italic');
      text-align: center;
    }

    &__include-status {
      @include text-styles__body(1, 'medium');
      text-align: center;
    }
  }
}

@media (min-width: $tabletWidth) {
  .review-footer {
    &__button {
      width: $button-width--x-large;
    }
  }
}
