@import '../../styles/variables.scss';
@import '../../styles/mixins/typography.scss';
@import '../../styles/mixins/skeleton.scss';

.review-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem auto 0;
  gap: 1rem;

  .review-page-container {
    width: $smallContentWidth;

    &__body {
      margin-top: 1.25rem;
    }

    .cpt-copyright-notice {
      @include text-styles__body(3, 'normal', 'italic');
      margin-top: 3.75rem;
    }
  }

  &__skeleton {
    @include skeleton;
    &--header {
      height: 3.5rem;
    }
    &--details {
      height: 43.75rem;
      margin-top: 1.25rem;
    }
    &--info {
      height: 18.75rem;
    }
    &--images {
      height: 100vh;
    }
  }
}

@media (min-width: $tabletWidth) {
  .review-page {
    .review-page-container {
      width: $mediumContentWidth;
      &__body {
        margin-top: 1.75rem;
      }

      .cpt-copyright-notice {
        margin-top: 3.5rem;
      }
    }
    &__skeleton {
      &--header {
        height: 3.5rem;
      }
      &--details {
        height: 11.25rem;
        margin-top: 1.75rem;
      }
      &--info {
        height: 32.0625rem;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .review-page {
    .review-page-container {
      width: $largeContentWidth;
      &__body {
        margin-top: 2rem;
      }

      .cpt-copyright-notice {
        margin-top: 4.75rem;
      }
    }
    &__skeleton {
      &--header {
        height: 6.75rem;
      }
      &--details {
        height: 33.125rem;
        margin-top: 2rem;
      }
      &--info {
        height: 12.1875rem;
      }
    }
  }
}
