@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

strong {
  font-weight: 500;
}

em {
  font-style: italic;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 96px;
  line-height: 127px;
}

h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 70px;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
}

h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 40px;
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}

/* ----------------- table mixin ---------------------- */

@mixin tables {
  table {
    th {
      color: $rekap_grey--light;
      font-size: 0.875rem;
      padding: 0.5rem;
      border-top: 1px solid $rekap_grey--light;
      border-bottom: 1px solid $rekap_grey--light;
    }
    td {
      min-width: 140px;
      padding: 0.5rem;
      border-bottom: 1px solid $rekap_grey--light;
      position: relative;
      &:last-child {
        border-right: 0;
      }
    }
  }
}
