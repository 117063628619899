@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/typography';

.surgery-documents {
  &__container {
    width: 100%;
    .kaliber-card {
      gap: 1.5rem;
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__text {
    width: max-content;
    @include text-styles__body(1, 'normal', $rekap_teal);
    border: 2px solid transparent;

    &:hover {
      cursor: pointer;
      border-bottom-color: $rekap_teal;
    }
  }
}
