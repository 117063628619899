@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins/_typography.scss';

.review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    &__image {
      border-radius: $borderRadius--large;
      width: 13.125rem;
      height: 13.125rem;
      object-fit: cover;
    }

    &__checkbox {
      display: flex;
      gap: 1rem;
      @include text-styles__body(1);

      &__input {
        width: 1.125rem;
        height: 1.125rem;
        accent-color: $rekap_grey--x-dark;
      }
    }
  }

  .image-category {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &__label {
      @include text-styles__title(3);
    }

    &__selection {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;

      .segmented-control {
        &__segment {
          width: 8.75rem;
          padding: 0;
        }
      }
    }
  }

  .show-labels {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &__label {
      @include text-styles__title(3);
    }

    &__anatomy-labels {
      .label-button {
        border-radius: 3.75rem;
      }

      .toggle-button-group {
        gap: 0.5rem;
      }
    }
  }

  .notes {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__label {
      @include text-styles__title(1);
      font-weight: 500;
    }
  }

  .action-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__save {
      margin: 0;
      height: 2rem;
      @include text-styles__button(2);
    }

    &__cancel {
      margin: 0;
      height: 2rem;
      @include text-styles__button(2);
    }
  }
}

@media (min-width: $tabletWidth) {
  .review-container {
    .image-content {
      gap: 1rem;
      margin-bottom: 0;

      &__image {
        width: 20.375rem;
        height: 20.375rem;
        object-fit: cover;
      }

      &__checkbox {
        align-items: center;
        height: 2.5rem;
      }
    }

    .image-category {
      &__selection {
        .segmented-control {
          .segment {
            width: 8.75rem;
          }
        }
      }
    }

    .action-buttons {
      flex-direction: row-reverse;
      justify-content: flex-start;
      gap: 1rem;

      &__save {
        height: 2.5rem;
        @include text-styles__button(1);
      }

      &__cancel {
        height: 2.5rem;
        @include text-styles__button(1);
      }
    }
  }
}
