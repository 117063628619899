@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins/typography';

.action-menu {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.menu-list {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-85%, 13%);
  width: 14.1875rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: $borderRadius;
  background-color: $rekap_white;
  box-shadow: $boxShadow;
  &__link {
    height: 3rem;
    width: 100%;

    &:hover {
      background-color: $rekap_cream;
    }
  }
  &__text {
    @include text-styles__body(2);
    margin: 0.875rem auto 0.875rem 1.5rem;

    &--delete {
      @include text-styles__body(2, 'normal', $rekap_red);
    }
  }
}

.burger-button {
  margin: 0;
}

button {
  all: unset;
}
