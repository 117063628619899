@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/border';
@import '../../../../styles/mixins/typography';

.emergency-contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  &__text {
    width: 100%;
    @include text-styles__body(1, 'medium', $rekap_black);
  }
}

.edit-emergency-contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.edit-modal__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .btn--primary {
    height: 2rem;
    @include text-styles__button(2);
  }

  .btn--cancel {
    max-width: 16.875rem;
    height: 1.875rem;
    @include text-styles__button(2);
  }
}

@media (min-width: $tabletWidth) {
  .edit-emergency-contact-form {
    &__input-container {
      max-width: 21.75rem;
    }
  }

  .edit-modal__buttons {
    flex-direction: row-reverse;

    .btn--primary {
      max-width: 10rem;
      height: 2.5rem;
    }

    .btn--cancel {
      max-width: 6.5rem;
      height: 2.375rem;
    }
  }
}
