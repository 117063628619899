@import '../../styles/variables.scss';
@import '../../style.scss';

.facility-container {
  width: 80%;
  margin: auto;

  .info-container {
    margin-top: 1.5rem;
  }

  .add-staff {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .title-container {
    display: flex;
    align-items: center;

    &_header {
      color: $rekap_black;
      text-align: left;
      margin-top: 1.875rem;
      margin-bottom: 1.25rem;
      margin-right: 1.5625rem;
    }
  }

  .info-row {
    margin-bottom: 0.9375rem;
  }

  .nav-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.facility-staff-table {
  width: 100%;
  @include tables;
  table {
    width: 100%;
  }

  .filters-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: flex-end;
    .left-container {
      flex-direction: row;
      width: 100%;
      display: flex;
      align-items: flex-end;
    }

    .right-container {
      margin-left: auto;
    }

    .filter {
      margin-right: 24px;
      width: 245px;

      div:first-child {
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }
  }

  .search-filter {
    margin-right: 24px;
  }
}
