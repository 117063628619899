@import '../../../../../../../../styles/mixins/_typography.scss';

.meniscus-repair {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 0.5rem;

  .ktf-container {
    width: 100%;

    .ktf-input-text {
      padding: 0.5rem;
      height: 2.5rem;
    }
  }

  .multi-select-container .multi-select-text-container .multi-select-label {
    @include text-styles__body(1);
    height: 1.5rem;
    color: $rekap_black;
  }

  .multi-select-container .multi-select-wrapper .multi-select-input-container {
    width: 21.75rem;
  }

  .repair-length-component-label {
    @include text-styles__body(1);
  }

  .repair-length-and-unit-component-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .k-text-field {
      width: 5rem;
      margin-top: 0.25rem;
    }

    .ks-container {
      width: 5.5rem;

      // Set height to 0 to align the 2 repair length and the units
      .ks-label {
        height: 0;
      }
    }
  }

  .additional-details {
    .ktf-container {
      width: 100%;

      .ktf-text-container {
        .ktf-label {
          margin: 0;
          height: auto;
          @include text-styles__body(1);
        }
      }

      .ktf-textarea-container {
        margin-top: 0.25rem;
        padding: 0;
      }
    }
  }
}

//Additionally adjust the css for the 29883 component because divs rendered inside it are different
.medial-and-lateral-29883-component {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .region-of-meniscus-and-repair-length-component {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media (min-width: $tabletWidth) {
  .meniscus-repair .ktf-container .ktf-input-text {
    height: 1.5rem;
    padding: 0.75rem 0.5rem;
  }
}
