@import './style.scss';
@import './styles/variables.scss';

body {
  font-family: 'Inter', sans-serif;
  background-color: $rekap_cream;
  color: $rekap_black;
}

.global-container {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  min-height: 100vh;
}

.page-container {
  width: 100%;
  min-height: 100vh;
}

@media (min-width: $desktopWidth) {
  .global-container {
    padding-top: 7.4rem;
  }
}
