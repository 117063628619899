@import '../../styles/variables.scss';

.external-container {
  margin-top: 3rem;
}
.report-container {
  display: flex;
  flex-direction: row;

  .side-stepper {
    max-width: 325px;
    width: 100%;

    .share-mobile {
      display: none;
    }
  }

  .report {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    max-width: 1200px;
    margin-bottom: 3rem;
    .report-info-container {
      padding: 0 30px;
    }
    .overview-image {
      width: 100%;
    }

    .report-disclaimer {
      text-align: left;
    }
  }
}

.report-survey {
  margin-top: 1.875rem;
  margin-bottom: 5.625rem;
  display: flex;
}

.divider {
  margin: 0;
}

@media (max-width: 1435px) {
  .report-container {
    .side-stepper {
      max-width: 0px;
      .floating-stepper {
        display: none;
      }

      .share-mobile {
        display: block;
      }
    }
  }
}

@media (min-width: 701px) {
  .report-container {
    .images-cont {
      padding-top: 2.8125rem;
    }

    .surgeon-cont {
      padding-top: 1.25rem;
    }
  }
}

@media (max-width: 700px) {
  .report-container {
    .report {
      border: none;
      .overview-image {
        display: none;
      }
    }
  }
}
