@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.autocomplete-search-container {
  display: flex;
  flex-direction: column;

  .autocomplete-search-title {
    height: 1rem;
    margin: 0 0 0.25rem 0;
    @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
  }

  .autocomplete-search-input-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    flex-wrap: wrap;

    .autocomplete-search-input-container {
      position: relative;
      width: 100%;

      ::placeholder {
        color: $rekap_grey;
      }

      .autocomplete-search-input-field-container {
        display: flex;
        align-items: center;
        @include border($rekap_grey--light);
        border-radius: 0.25rem;
        padding: 0 0.5rem;

        .autocomplete-search-input {
          height: 2.75rem;
          display: flex;
          justify-content: flex-start;
          border: none;
          outline: none;
          width: 100%;
          @include text-styles__body(1);
          text-overflow: ellipsis;
        }

        .search-icon {
          border-left: 1px solid $rekap_grey--light;
          padding-left: 0.625rem;
        }

        &:focus-within {
          @include border($rekap_grey--x-dark);
          border-radius: $borderRadius;
        }
      }

      .autocomplete-search-input-field-container-error-border {
        border-color: $rekap_red;
      }
    }

    .autocomplete-search-input-container ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 12.5rem;
      overflow-y: auto;
      background-color: $rekap_white;
      margin-top: 0;
      padding: 0;
      list-style: none;
    }

    .autocomplete-search-input-container li {
      padding: 0.5rem;
      cursor: pointer;
    }

    .autocomplete-search-input-container li:hover:not(.active):not(.selected) {
      color: $rekap-black;
      background-color: $rekap_cream;
    }

    .autocomplete-search-input-container .active {
      background-color: $rekap_white;
      background-color: $rekap_cream;
    }

    .autocomplete-search-input-container input:focus + ul {
      display: block;
    }

    .autocomplete-search-input-container .selected {
      color: $rekap_white;
    }

    .autocomplete-search-button {
      box-sizing: border-box;
      height: 2.5rem;
      display: flex;
      width: 7.5rem;
      margin: 0 1rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      @include border($rekap_grey--light);
      background-color: $rekap_white;
      color: $rekap_grey--light;
      text-align: center;
      border-color: $rekap-grey;
      @include text-styles__button(1);
    }

    .valid {
      @include border($rekap-black);
      color: $rekap-black;
    }
  }

  .autocomplete-search-input-error {
    margin-top: 0.25rem;
    color: $rekap_red;
    font-size: $remFontSizeXXS;
    line-height: 1rem;
  }
}
