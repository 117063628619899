@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins/_border.scss';
@import '../../../../../../styles//mixins/_typography.scss';

.review-patient-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .item {
    &__row {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__label {
      @include text-styles__body(2);
      height: 1rem;
      margin-bottom: 0.25rem;
    }

    &__value {
      @include text-styles__body(1, 'medium');

      &--no-entry {
        @include text-styles__body(1, 'normal', $rekap_grey--x-dark, 'italic');
      }
    }
  }
}

@media (min-width: $tabletWidth) {
  .review-patient-info-container {
    .item {
      width: 200px;

      &__row {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: start;
        gap: 0.8rem;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .review-patient-info-container {
    .item {
      width: 12.5rem;

      &__row {
        gap: 1rem;
      }

      &__value {
        @include text-styles__body(1, 'medium');

        &--no-entry {
          @include text-styles__body(1, 'medium', 'italic');
          height: 1.5rem;
        }
      }
    }
  }
}
