@import '../../../../../../../../styles/variables.scss';
@import '../../../../../../../../styles/mixins/_typography.scss';

.post-op-instructions-multi-select {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .selected-instructions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__item {
      display: flex;
      gap: 0.5rem;
    }

    &__item-text {
      @include text-styles__body(1, 'normal', 'italic');
    }

    &__item-button--clear {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}
