@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.kaliber-modal-container {
  display: none;
  position: fixed;
  z-index: $modalZIndex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  .kaliber-modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $rekap_white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: $boxShadow;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-height: 90%;
    width: 20rem;
    overflow: hidden;

    .kaliber-modal-title-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.625rem;
      width: 100%;
      .kaliber-modal-header-icon {
        img {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
    .kaliber-modal-title-icon-container-left {
      height: auto;
      width: 17rem;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    .kaliber-modal-header-text {
      @include text-styles__headline(3);
      text-align: center;
      height: 3.5rem;
    }

    .kaliber-modal-close-icon {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;
    }

    .medium {
      width: 17rem;
      height: auto;
    }
  }
}

.show-modal {
  display: flex;
}

@media (min-width: $tabletWidth) {
  .kaliber-modal-container {
    .kaliber-modal-content-container {
      padding: 3rem 2.25rem;
      .kaliber-modal-title-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.625rem;
        width: 100%;
      }
      .kaliber-modal-center-icon {
        flex-direction: column;
        align-items: center;
      }
      .kaliber-modal-left-icon {
        flex-direction: row;
      }
      .kaliber-modal-title-icon-container-left {
        flex-direction: row;
        justify-content: flex-start;
        height: 2rem;
      }
      .kaliber-modal-title-icon-container-right {
        justify-content: flex-end;
      }

      .kaliber-modal-close-icon {
        width: 2rem;
        height: 2rem;
        top: 2rem;
        right: 2rem;
      }

      .kaliber-modal-header-text {
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
        color: $rekap_grey--x-dark;
        height: auto;
      }
      .kaliber-modal-header-text-left {
        display: flex;
        align-items: flex-start;
      }
      .kaliber-modal-header-text-right {
        display: flex;
        align-items: flex-end;
      }
    }

    .default {
      width: 41rem;
    }

    .medium {
      width: 41rem;
      height: 12rem;
    }
  }
}
