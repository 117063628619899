@import '../../../styles/variables.scss';
@import '../../../styles/mixins/_border.scss';
.od-outer-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .od-custom-container,
  .od-default-container {
    display: flex;
    flex-direction: column;

    .od-container {
      box-shadow: none;
      background-color: transparent;
      padding: 0;
      display: flex;
      width: 100%;
      height: 64px;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;

      :hover {
        box-shadow: none;
      }

      .od-upload-icon {
        path {
          stroke: $rekap_grey--x-dark;
        }
      }
    }
  }

  .od-custom-container {
    .od-container {
      @include border($rekap_grey--light);
      border-radius: 6px;
    }
  }

  .od-default-container {
    .od-container {
      @include border($rekap_grey--light);
      border-radius: 6px 6px 0 0;
    }
  }

  .od-icon-container {
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .od-icon {
      width: 33px;
      height: auto;
      path {
        stroke: $rekap_teal--light;
      }
    }
  }

  .od-pop-over-icon-container {
    position: absolute;
    z-index: $modalZIndex;
    right: 8px;
    top: 8px;

    .od-pop-over-icon-container-inner {
      position: relative;
      display: flex;
      align-self: flex-end;
      background-color: $rekap_white;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      padding: 7px 10px;
      .od-icon {
        width: 22px;
        height: auto;
        margin: 5px;
      }
      &:hover {
        box-shadow: $boxShadow;
      }
      .od-eye-icon {
        color: $rekap_grey--x-dark;
        padding: 0;

        path {
          stroke: none;
        }
      }
      .od-delete-icon {
        color: $rekap_red;

        path {
          stroke: none;
        }
      }
    }
  }
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: $modalZIndex - 1;
    left: 0;
    top: 0;
  }

  .od-text-container {
    font-style: italic;
    margin-right: 10px;
    overflow: hidden;

    .od-upload-text {
      font-size: 14px;
      color: $rekap_grey--x-dark;
    }

    .od-file-text {
      font-size: 14px;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .od-custom-file-text {
      font-size: 14px;
      max-width: 125px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .od-file-size-text {
      font-size: 12px;
      color: $rekap_grey;
    }
  }

  .od-disabled {
    color: $rekap_grey--x-dark;
    // not sure if this is the best way to do this but doing it for now
    .od-file-size-text {
      color: $rekap_grey--x-dark;
    }
  }

  .od-selected {
    color: $rekap_teal--light;
  }
}
