@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.kcti {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__label {
    margin: 0 0 0 0.25rem;
    width: 100%;
    height: $remFontSizeS;
    @include text-styles__body(3, 'normal', $rekap_grey--x-dark);
  }

  &__input-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__input-container {
    margin: 0;
    background-color: $rekap_white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    height: auto;
    @include border($rekap_grey--light);
    border-radius: 0.25rem;

    &:focus-within {
      @include border($rekap_grey--x-dark);
    }

    &--error {
      border-color: $rekap_red;
    }
  }

  &__input-text {
    border: none;
    outline: none;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 1.5rem;
    resize: none;
    box-sizing: border-box;
    @include text-styles__body(1);
    padding: 0 0.125rem;

    &:focus-within,
    &:focus-visible {
      outline: none;
    }
  }

  &__remove-icon {
    cursor: pointer;
  }

  &__error-text {
    margin-top: 0.25rem;
    @include text-styles__body(3);
    color: $rekap_red;
  }
}
