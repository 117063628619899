@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.surgeries-page {
  width: $smallContentWidth;
  margin: 1.5rem auto 0;

  &__title-container {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  &__filters {
    margin: 1rem 0;
    gap: 1rem;
    display: flex;
    flex-direction: row;
  }

  &__filters-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__add-surgery {
    display: none;
  }

  &__title {
    @include text-styles__headline(3);
  }

  &__entries {
    @include text-styles__body(1, 'normal', $rekap_black, 'italic');
    margin-bottom: 0.5rem;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
@media (min-width: $tabletWidth) {
  .surgeries-page {
    width: $mediumContentWidth;
    &__add-surgery {
      display: flex;
    }
  }
}
@media (min-width: $desktopWidth) {
  .surgeries-page {
    width: $largeContentWidth;

    &__title-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
