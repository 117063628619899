@import '../../styles/variables.scss';

.pp-container {
  width: 80%;
  margin: auto;
}

h3 {
  color: $rekap_black;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 600;
}

p,
address {
  font-size: 17px;
}

ol,
ul {
  font-size: 17px;
}
