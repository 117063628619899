@import '../../../../../../../../styles/variables.scss';
@import '../../../../../../../../styles/mixins/_typography.scss';

.bankart-detail-card {
  .additional-details {
    .ktf-container {
      width: 100%;

      .ktf-input-text {
        padding: 0.5rem;
        height: 2.5rem;
      }

      .ktf-text-container {
        margin-top: 1rem;

        .ktf-label {
          margin: 0;
          height: auto;
          @include text-styles__body(1);
        }
      }

      .ktf-textarea-container {
        margin-top: 0.25rem;
        padding: 0;
      }
    }
  }
}

@media (min-width: $tabletWidth) {
  .bankart-detail-card {
    .additional-details .ktf-container .ktf-input-text {
      height: 1.5rem;
      padding: 0.75rem 0.5rem;
    }
  }
}
