@import '../../../../../../styles/mixins/_typography.scss';

.review-subtitle {
  margin-bottom: 0.625rem;

  @include text-styles__body(1, 'medium');
  .disabled-subtitle {
    color: $rekap_grey--dark;
  }
}

.kaliber-divider {
  border-bottom-color: $rekap_grey--light;
}
