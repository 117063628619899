@import '../../styles/variables.scss';

.custom-modal {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-top: 200px;
  transform: translate(-50%, -50%);
  width: 280px;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 24px;
  .modal-title {
    margin-bottom: 20px;
  }
  .modal-title-error {
    margin-bottom: 20px;
    color: $rekap_red;
  }
  .modal-body-text {
    margin-bottom: 20px;
  }
  .details-container {
    margin-bottom: 20px;
  }
}
.custom-modal-button-container {
  display: flex;
  justify-content: flex-end;
}
