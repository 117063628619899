@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.kl-datepicker {
  max-width: 100%;
}

.kl-dob-textfield {
  width: 100%;
  color: $rekap_grey;
}

.kdp-caption {
  margin: 0 0 0.25rem 0;
  width: 100%;
  height: 1rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $remFontSizeXS;
  line-height: 1rem;
  letter-spacing: 0.25px;
  color: $rekap_grey--x-dark;
}

.kl-dob-error-border {
  .MuiOutlinedInput-root {
    fieldset {
      border-color: $rekap_red;
    }

    &:hover fieldset {
      border-color: $rekap_red;
    }

    &.Mui-focused fieldset {
      @include border($rekap_grey);
    }
  }
}

.kl-dob-short {
  .SingleDatePickerInput__withBorder {
    height: 2.875rem;
  }
}

.kl-dob-input-error {
  color: $rekap_red;
  font-size: 12px;
  margin-top: 4px;
  line-height: 16px;
}

.kl-date-picker-uneditable {
  transform: translateY(-0.25rem);

  .MuiOutlinedInput-input {
    padding: 0;
  }
}

.kl-date-picker-editable-title {
  display: flex;
  align-items: center;
  color: $rekap_grey--x-dark;
  font-weight: 400;
  font-family: 'Inter';
  line-height: 1rem;
  letter-spacing: 0.25px;
  font-size: 0.875rem;
  transform: translateY(-0.25rem);
  margin-top: 0.25rem;
}
