@import '../variables.scss';

@mixin text-styles__numbers($color: $rekap_black) {
  font-family: Inter;
  font-size: 3.625rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -0.29px;
  color: $color;
}

@mixin text-styles__display($number, $color: $rekap_black) {
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: normal;
  color: $color;

  @if $number == 1 {
    font-size: 3.5625rem;
    font-weight: 300;
    line-height: 1.12;
    letter-spacing: -0.5px;
  } @else if $number == 2 {
    font-size: 2.9375rem;
    line-height: 1.11;
  } @else if $number == 3 {
    font-size: 2.25rem;
    line-height: 1.22;
  }
}

@mixin text-styles__headline($number, $color: $rekap_black) {
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color;

  @if $number == 1 {
    font-size: 2.0625rem;
    line-height: 1.21;
    letter-spacing: 0.25px;
  } @else if $number == 2 {
    font-size: 1.75rem;
    line-height: 1.29;
  } @else if $number == 3 {
    font-size: 1.4375rem;
    line-height: 1.22;
  }
}

@mixin text-styles__title($number, $weight: 'normal', $color: $rekap_black) {
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $color;

  @if $number == 1 {
    font-size: 1.1875rem;
    line-height: 1.26;
    letter-spacing: 0.15px;
  } @else if $number == 2 {
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: 0.5px;
  } @else if $number == 3 {
    font-size: 0.875rem;
    line-height: 1.14;
    letter-spacing: 0.25px;
  }

  @if $weight == 'medium' {
    font-weight: 500;
  } @else if $weight == 'semibold' {
    font-weight: 600;
  }
}

@mixin text-styles__body(
  $number,
  $weight: 'normal',
  $color: $rekap_black,
  $style: 'normal'
) {
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $color;

  @if $number == 1 {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.15px;
  } @else if $number == 2 {
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.25px;
  } @else if $number == 3 {
    font-size: 0.75rem;
    line-height: 1.33;
    letter-spacing: 0.4px;
  }

  @if $weight == 'medium' {
    font-weight: 500;
  } @else if $weight == 'semibold' {
    font-weight: 600;
  }

  @if $style == 'italic' {
    font-style: italic;
  }
}

@mixin text-styles__button($number) {
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.4px;

  @if $number == 1 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0.1px;
  } @else if $number == 2 {
    font-size: 0.75rem;
    line-height: 1.33;
  } @else if $number == 3 {
    font-size: 0.6875rem;
    line-height: 1.45;
  }
}
