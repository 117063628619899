@import '../variables.scss';

@mixin skeleton() {
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: $borderRadius;
  width: $smallContentWidth;

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }

  @media (min-width: $tabletWidth) {
    height: 18.125rem;
    width: $mediumContentWidth;
  }
  @media (min-width: $desktopWidth) {
    height: 12.813rem;
    width: $largeContentWidth;
  }
}
