@import '../../styles/variables.scss';
@import '../../styles/mixins/_button.scss';

.toggle-button-group {
  display: flex;
  flex-wrap: wrap;

  .toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    @include text-styles__button(2);
    background: $rekap_white;
    color: $rekap_grey--x-dark;
    border: solid 1px $rekap_grey--light;
    transition: all 0.3s;
    padding: 0.5rem 0.625rem;
    &:hover {
      background: rgba($rekap_grey--x-light, 0.8);
      transition: all 0.3s;
    }
  }

  .selected.primary {
    justify-content: flex-start;
    background: $rekap_black;
    color: $rekap_white;
    border: solid 1px $rekap_black;
  }

  .selected.secondary {
    justify-content: flex-start;
    background: $rekap_teal--x-light;
    color: $rekap_white;
    border: solid 1px $rekap_teal--x-light;
  }

  .toggle-check-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.375rem;
  }

  .toggle-button:first-child {
    border-radius: 8px 0 0 8px;
  }

  .toggle-button:last-child {
    border-radius: 0 8px 8px 0;
  }

  .toggle-button-outlined:first-child {
    border-radius: 8px 0 0 8px;
    border-right-style: none;
  }

  .toggle-button-outlined:last-child {
    border-radius: 0 8px 8px 0;
  }
}
