@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.chip-multi-select-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  @include text-styles__button(2);

  &__chip {
    height: 1.875rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    padding: 0 0.625rem;
    border-radius: 3.75rem;
    border: solid 1px $rekap_grey--light;
    background-color: $rekap_white;
    cursor: pointer;
    color: $rekap_grey--x-dark;

    &--selected {
      background-color: $rekap_black;
      border-color: $rekap_black;
      color: $rekap_white;
    }
  }

  &__check-circle-icon {
    width: 1.0625rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__unchecked-circle-icon {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    border: solid 1px $rekap_grey;
  }
}

@media (min-width: $tabletWidth) {
  .chip-multi-select-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
