@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/typography';

.surgery-card {
  max-width: 23.375rem;
  padding: 2rem 1.5rem;
  margin-bottom: 1.5rem;
  background-color: $rekap_white;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: flex-end;
  }
  &__name {
    @include text-styles__title(1);
    color: $rekap_teal--light;
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;

    &--link {
      display: inline;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
      }
    }
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }
  &__info-field {
    width: 44%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    &--title {
      @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
    }
    &--details {
      @include text-styles__body(1, 'medium');
    }
  }
}

.procedures-report-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 0.5rem;

  .report-read-msg-container {
    margin-left: auto;
  }

  .report-read-status {
    &__viewed {
      @include text-styles__body(3, 'normal', $rekap_grey--dark, 'italic');
    }

    &__not-viewed {
      @include text-styles__body(3, 'normal', $rekap_red, 'italic');
    }
  }
}
     
.pending-review-container {
  display: flex;
  width: 100%;
  justify-content: end;

  .btn {
    &--primary {
      &.disabled-review-button {
        background-color: $rekap_grey;
        color: $rekap_grey--dark;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .pending-review-modal {
    .kaliber-modal-container {
      .default {
        width: 17rem;
      }

      .kaliber-modal-content-container {
        .kaliber-modal-title-icon-container {
          align-items: flex-start;
        }

        .kaliber-modal-header-text {
          height: 1.5rem;
          @include text-styles__title(1, 'medium', $rekap_black);
        }
      }

      .modal-text {
        margin-top: 1.5rem;
        text-align: left;
        width: 100%;
        @include text-styles__body(1);
      }
    }
  }
}

@media (min-width: $tabletWidth) {
  .surgery-card {
    max-width: 45.5rem;

    &__button {
      justify-content: end;
    }

    &__info-field {
      width: 8.75rem;
    }
  }

  .pending-review-container {
    .pending-review-modal {
      .kaliber-modal-container {
        .default {
          width: 41.5rem;
        }

        .kaliber-modal-content-container {
          padding: 1.5rem 2rem;

          .kaliber-modal-header-text {
            @include text-styles__headline(3);
          }

          .kaliber-modal-close-icon {
            top: 1.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .surgery-card {
    max-width: 75rem;
    padding: 2rem 2.5rem;

    &__button {
      width: fit-content;
    }
    &__info {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;
    }
    &__info-field {
      width: 12.5rem;
    }
  }

  .procedures-report-wrapper {
    align-items: flex-start;
    flex-direction: row;
    gap: 0;

    .report-read-msg-container {
      margin-left: 2.5rem;
    }
  }
}
