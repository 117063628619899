@import '../../styles/variables.scss';
@import '../../styles/mixins/_skeleton.scss';

.staff-page {
  // Copied from the App.scss file for spacing from the headerbar
  margin: 68px auto 0;
  max-width: $largeContentWidth;
  width: 100%;

  &__skeleton {
    @include skeleton;
    &--header {
      height: 3.5rem;
    }
    &--table {
      height: 80vh;
    }
  }

  &__skeleton-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
}
