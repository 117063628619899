@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.radio-control {
  accent-color: $rekap-black;
  display: flex;
  gap: 1.125rem;
  padding-left: 0.4rem;

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
  }

  &--label {
    color: $rekap-black;
    @include text-styles__body(1);
  }
}

.disabled {
  color: $rekap_grey--light;
}
