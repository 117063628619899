@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/_border.scss';
@import '../../../../styles/mixins/_typography.scss';

.surgery-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__info-container {
    width: 12.5rem;
  }

  &__label {
    width: 100%;
    @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
    margin-bottom: 0.25rem;
  }

  &__text {
    width: 100%;
    @include text-styles__body(1, 'medium', $rekap_black);
  }
}

.planned-procedures {
  @include border;
  border-radius: 0.5rem;
  padding: 1rem;

  &__divider {
    border-top: 1px solid $rekap_grey--light;
    margin: 1rem 0;
  }
  &__label {
    @include text-styles__body(1, 'medium');
    margin-bottom: 0.5rem;
  }
  &__custom-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    @include text-styles__body(1);
    word-wrap: break-word;
  }
}

.surgery-details-edit-modal {
  .kl-dob-tall {
    .SingleDatePickerInput__withBorder {
      width: 17rem;
    }
  }
}

@media (min-width: $tabletWidth) {
  .surgery-details {
    &__info {
      flex-direction: row;
      gap: 2.5rem;
    }
  }

  .surgery-details-edit-modal {
    .kl-dob-tall {
      .SingleDatePickerInput__withBorder {
        width: 21.625rem;
        height: 2.875rem;
      }
    }

    .edit-patient-demographics-form {
      &__name-fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-end;
      }
    }
  }
}
