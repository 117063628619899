@import '../../../styles/variables.scss';

.patient-pay-item-container {
  position: relative;
  .patient-pay-item-image {
    height: 25rem;
    transform: translate(-25%, 0%);
  }
  .patient-pay-item-logo {
    height: 2rem;
    width: 12.875rem;
    margin-bottom: 1rem;
  }
  .patient-pay-item-text-container {
    position: absolute;
    background-color: rgba(18, 34, 117, 0.6);
    top: 0;
    height: 100%;
    width: 18.5rem;
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    .patient-pay-item-body-text {
      color: $rekap_white;
      font-size: $remFontSizeS;
      line-height: 1.25rem;
      .item-bold-text {
        font-weight: bold;
      }
    }
    .patient-pay-upgrade-button {
      margin-top: 1rem;
    }
  }
}

@media screen and (min-width: $tabletWidth) {
  .patient-pay-item-container {
    .patient-pay-item-image {
      height: 26.5rem;
      transform: initial;
    }
    .patient-pay-item-logo {
      height: 3rem;
      width: 19.5rem;
    }
    .patient-pay-item-text-container {
      width: 29.3125rem;

      .patient-pay-item-body-text {
        font-size: 1.25rem;
        line-height: 1.75rem;
        width: 21.5625rem;
      }
    }
  }
}

@media screen and (min-width: $desktopWidth) {
  .patient-pay-item-container {
    .patient-pay-item-image {
      height: 40rem;
      transform: initial;
    }
    .patient-pay-item-logo {
      height: 3.5625rem;
      width: 23.1875rem;
    }
    .patient-pay-item-text-container {
      width: 38.75rem;
      padding: 5.4375rem 4.5rem;

      .patient-pay-item-body-text {
        font-size: 1.75rem;
        line-height: 2.5rem;
        width: 29.6875rem;
        margin-top: 0.625rem;
      }
      .patient-pay-upgrade-button {
        margin-top: 1.5rem;
      }
    }
  }
}
