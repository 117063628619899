@import '../../styles/variables.scss';

.alert-pop-up {
  display: block;
  padding: 10px 15px;
  border-radius: $borderRadius;
  font-weight: 300;
  position: fixed;
  z-index: $modalZIndex;
  cursor: pointer;
  top: 6rem;
  left: 50%;
  transform: translate(-50%);
  background: $rekap_black;
  color: $rekap_grey--x-light;
  overflow-y: auto;
  max-height: 80%;
}

.alert-icon {
  margin-left: 0.625rem;
}

.alert-title {
  display: flex;
}

.show-children {
  display: block;
}

.hide-children {
  display: none;
}
