@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins/_border.scss';
@import '../../../../../styles/mixins/_typography.scss';

.date-picker {
  &__label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__label {
    margin: 0 0 4px 0;
    width: 100%;
    height: 1rem;
    @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
  }
}

/* -------------------------------------------------------------------------- */
/*                            REACT DATES OVERRIDES                           */
/* -------------------------------------------------------------------------- */

.SingleDatePicker {
  width: 100%;
}

.DateInput {
  width: 95%;
  height: 1.5rem;
  padding-left: 8px;
  padding-right: 8px;
}

.CalendarDay__default:hover {
  background: $rekap_grey--dark;
  color: $rekap_white;
}

.DateInput_fang {
  display: none;
}

.DayPicker_weekHeader,
.DayPicker_weekHeader_ul {
  color: $rekap_grey--dark;
  font-weight: 600;
  font-size: 1.05rem;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  font-family: 'Inter';
}

.CalendarMonth_caption {
  color: $rekap_black;
  font-family: 'Inter';
  font-size: $remFontSizeS;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: 0.15px;

  strong {
    font-weight: 600;
  }
}

.DayPickerNavigation_button__horizontalDefault,
.DayPickerNavigation_button__horizontalDefault:hover {
  border: none;
}

.DayPickerNavigation_svg__horizontal {
  color: $rekap_grey--dark;
  width: 24px;
  height: 24px;
}

.DateInput_input {
  font-family: 'Inter';
  font-weight: 400;
  color: $rekap_black;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  height: 1.5rem;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.DateInput_input__focused {
  background-color: transparent;
  border: none;
}

.DateInput_input:focus-visible {
  outline: none;
}

.SingleDatePickerInput__withBorder {
  @include border($rekap_grey--light);
  height: 48px;
  border-radius: $borderRadius;
  background-color: $rekap_white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:focus-within {
    @include border($rekap_grey--x-dark);
    border-radius: $borderRadius;
  }
}

.CalendarDay__default {
  @include border($rekap_grey--x-light);
  color: $rekap_black;
  line-height: 1.25rem;
  font-family: 'Inter';
  font-size: $remFontSizeXS;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.CalendarDay__blocked_out_of_range {
  color: $rekap_grey;
  line-height: 1.25rem;
  font-family: 'Inter';
  font-size: $remFontSizeXS;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.CalendarDay__selected_span {
  background: $rekap_grey--dark; //background
  color: white; //text
  @include border($rekap_grey--dark); //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $rekap_grey--dark;
  color: white;
  border: 1px solid $rekap_grey--dark;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $rekap_grey--light;
  color: $rekap_grey--x-dark;
  @include border($rekap_grey--x-light);
}

.SingleDatePicker_picker {
  top: 3.25rem !important; // this !important tag is needed to override MUI inbuilt styles
  border: $rekap_grey--light;
  border-radius: 0.25rem;
  box-shadow: $boxShadow;
}

.SingleDatePickerInput_calendarIcon {
  margin: 0;
  padding: 0.75rem 0.5rem 0.625rem 0.5rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.kl-dob-tall {
  .SingleDatePickerInput__withBorder {
    height: 48px;
  }
}

.kl-dob-error-border {
  .SingleDatePickerInput__withBorder {
    @include border($rekap_red);
  }
}

.kl-dob-input-error {
  color: $rekap_red;
  font-size: 12px;
  line-height: 16px;
}

.SingleDatePickerInput_clearDate {
  padding: 0px;

  &:hover {
    background-color: transparent;
  }
  &:active {
    outline: none;
  }
}
