@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: $rekap_grey--light !important;
  color: $rekap_black;
}

body .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $rekap_grey--x-dark;
  border-width: 1px;
}

body .MuiOutlinedInput-input {
  font-family: 'Inter';
  color: $rekap_black;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  font-size: 1rem;
  font-weight: 400;
  height: 1.5rem;
  display: flex;
  align-items: center;
}

.MuiOutlinedInput-input-edit {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
