@import '../../style.scss';

@mixin center($vertical: true, $horizontal: true, $rotationDeg: 0deg) {
  position: absolute;
  @if $vertical and $horizontal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate($rotationDeg);
  } @else if $vertical {
    top: 50%;
    transform: translate(0, -50%) rotate($rotationDeg);
  } @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0) rotate($rotationDeg);
  }
}
