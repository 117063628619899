@import '../../styles/variables.scss';

.Empty-text {
  padding-top: 1.3rem;
  color: $rekap_black;
  text-align: center;
}

.Table {
  display: flex;
  flex-direction: column;

  table {
    th {
      color: $rekap_grey--dark;
      font-size: $remFontSizeXS;
      padding: 0.5rem;
      border-top: 1px solid $rekap_grey--light;
      border-bottom: 1px solid $rekap_grey--light;

      &:nth-child(1) {
        background-image: url(../../assets/icons/sort-icon.svg);
        background-repeat: no-repeat;
        background-position: 80% 50%;
      }
    }
    td {
      padding: 0.5rem;
      border-bottom: 1px solid $rekap_grey--light;
      position: relative;

      &:last-child {
        border-right: 0;
      }
    }
  }
}
