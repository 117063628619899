@import '../../styles/variables.scss';

.sg-title,
.sg-subtitle {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
}

.sg-title {
  font-weight: 300;
  font-size: 3rem;
}

.sg-subtitle {
  font-weight: 200;
  font-size: 2rem;
}

.html-examples {
  color: #fff;
  resize: none;
  margin: 0.5rem auto;
  display: block;
  width: 100%;
  height: 8rem;
  width: 24rem;
  background: #000;
  border: none;
  overflow: scroll;
  font-family: monospace;
  padding: 0.5rem;
}

.scss-colors {
  margin-bottom: 4rem;

  .color-set {
    display: flex;
    width: 80%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;

    .color {
      margin-left: -1rem;

      .color-swatch {
        background: #fff;
        border-radius: 50%;
        height: 5rem;
        width: 5rem;
        margin: 1rem auto;
      }

      .color-meta {
        text-align: center;
        display: block;
        font-size: 0.6rem;
      }

      .selected-color {
        color: #000;
        font-weight: 300;
      }
    }
    .color:hover {
      color: #fff;
      font-weight: 300;
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.fonts {
  display: flex;
  flex-direction: column;
}

.fontSizeXXXXXL {
  font-size: $remFontSizeXXXXXL;
}
.fontSizeXXXXL {
  font-size: $remFontSizeXXXXL;
}
.fontSizeXXXL {
  font-size: $remFontSizeXXXL;
}
.fontSizeXXL {
  font-size: $remFontSizeXXL;
}
.fontSizeXL {
  font-size: $remFontSizeXL;
}
.fontSizeL {
  font-size: $remFontSizeL;
}
.fontSizeM {
  font-size: $remFontSizeM;
}
.fontSizeS {
  font-size: $remFontSizeS;
}
.fontSizeXS {
  font-size: $remFontSizeXS;
}
.fontSizeXXS {
  font-size: $remFontSizeXXS;
}

@media screen and (min-width: $mobileWidth) {
  .fontSizeXXXXXL {
    font-size: $remFontSizeXXXXXL;
  }
  .fontSizeXXXXL {
    font-size: $remFontSizeXXXXL;
  }
  .fontSizeXXXL {
    font-size: $remFontSizeXXXL;
  }
  .fontSizeXXL {
    font-size: $remFontSizeXXL;
  }
  .fontSizeXL {
    font-size: $remFontSizeXL;
  }
  .fontSizeL {
    font-size: $remFontSizeL;
  }
  .fontSizeM {
    font-size: $remFontSizeM;
  }
  .fontSizeS {
    font-size: $remFontSizeS;
  }
  .fontSizeXS {
    font-size: $remFontSizeXS;
  }
  .fontSizeXXS {
    font-size: $remFontSizeXXS;
  }
}
