@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.edit-procedure-details-container {
  @include border($rekap_grey--light);
  border-radius: 0.5rem;
  padding: 1rem;
  max-height: 15rem;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: $rekap_grey--dark transparent;
  scrollbar-width: thin;

  .region-line-divider {
    border-top: 1px solid $rekap_grey--light;
    margin: 1rem 0;
  }
  .region-label {
    @include text-styles__body(1, 'medium');
  }

  .surgery-side-container {
    .surgery-side-label {
      @include text-styles__title(3);
      width: 100%;
      color: $rekap_grey--x-dark;
      margin: 0.25rem 0;
    }
  }

  .custom-procedures-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    &__procedures-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .segmented-control {
    display: flex;

    &__segment {
      box-sizing: border-box;
      min-width: 4.625rem;
    }
  }

  .radio-buttons {
    display: none;
  }

  .cpt-procedure-common-name {
    margin-left: 1.5rem;
    max-width: 7rem;
    @include text-styles__body(2);
  }
}

.edit-procedure-details-add-procedure {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &__disclaimer {
    @include text-styles__body(3, 'normal', $rekap_grey--dark, 'italic');
  }
}

@media (min-width: $tabletWidth) {
  .edit-procedure-details-container {
    .segmented-control {
      &__segment {
        min-width: 5.5rem;
      }
    }

    .cpt-procedure-common-name {
      max-width: 31.5rem;
      @include text-styles__body(1);
    }
  }
}
