@import '../../../../../../../../styles/mixins/_typography.scss';

.meniscectomy-component-container {
  .meniscectomy-detail-card-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .additional-details {
      .ktf-container {
        width: 100%;

        .ktf-text-container .ktf-label {
          margin: 0;
          height: auto;
          @include text-styles__body(1);
        }

        .ktf-input-text {
          padding: 0.5rem;
          height: 2.5rem;
        }

        .ktf-textarea-container {
          margin-top: 0.25rem;
          padding: 0;
        }
      }
    }

    .multi-select-container .multi-select-text-container .multi-select-label {
      @include text-styles__body(1);
      height: 1.5rem;
      color: $rekap_black;
    }
  }
}

@media (min-width: $tabletWidth) {
  .meniscectomy-component-container {
    .meniscectomy-detail-card-container
      .additional-details
      .ktf-container
      .ktf-input-text {
      height: 1.5rem;
      padding: 0.75rem 0.5rem;
    }
  }
}
