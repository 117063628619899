@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins/typography';
.grouped-procedures {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.procedure-detail {
  display: flex;
  flex-direction: column;

  &__region-label {
    @include text-styles__body(1);
  }
}

.procedure-tag-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
  overflow: scroll;
  scrollbar-width: none;
}
// to remove the scrollbar
.procedure-tag-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media (min-width: $tabletWidth) {
  .procedure-detail {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  .procedure-tag-container {
    gap: 1rem;
  }
}
