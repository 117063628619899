@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/_border.scss';
@import '../../../../styles/mixins/_typography.scss';

.patient-profile-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__disclaimer {
    @include text-styles__body(3, 'normal', $rekap_grey--dark, 'italic');
    margin-top: 2rem;
  }
}

@media (min-width: $tabletWidth) {
  .patient-profile-page-container {
    &__disclaimer {
      margin-top: 2.25rem;
    }
  }
}

@media (min-width: $desktopWidth) {
  .patient-profile-contact {
    &__container {
      display: flex;
      gap: 1.5rem;
    }
  }

  .patient-profile-page-container {
    &__disclaimer {
      margin-top: 1.75rem;
    }
  }
}
