@import '../../../styles/variables.scss';
@import '../../../styles/mixins/_typography.scss';

.report-container {
  display: flex;
  flex-direction: row;
  gap: 1.625rem;

  .side-stepper {
    max-width: 325px;
    width: 100%;

    .share-mobile {
      display: none;
    }
  }

  .report {
    width: 100%;
    max-width: $desktopWidth;
    background-color: $rekap_white;

    .report-info-container {
      padding: 0 30px;
      border: none;
    }

    .overview-image {
      width: 100%;
    }

    &__divider {
      border-top: 1px solid $rekap_grey--light;
      margin: 1rem 0;
    }

    &__procedures {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1.5rem;

      &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.25rem;
      }

      &__region {
        @include text-styles__body(1);
      }

      &__description {
        @include text-styles__body(1, 'semibold');
      }
    }
  }
}

.report-survey {
  margin-top: 1.875rem;
  margin-bottom: 5.625rem;
  display: flex;
}

@media (max-width: 1435px) {
  .report-container {
    .side-stepper {
      max-width: 0px;
      .floating-stepper {
        display: none;
      }

      .share-mobile {
        display: block;
      }
    }
  }
}

@media (min-width: 701px) {
  .report-container {
    .images-cont {
      padding-top: 2.8125rem;
    }

    .surgeon-cont {
      padding-top: 1.25rem;
    }
  }
}

@media (max-width: 700px) {
  .report-container {
    .report {
      border: none;
      .overview-image {
        display: none;
      }
    }
  }
}
