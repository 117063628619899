@import '../../styles/variables.scss';
.display-none {
  display: none !important;
}

.expand-more-icon {
  right: 0.625rem;
  position: absolute;
  z-index: -1;
}

.icon-btn {
  position: absolute;
  right: 0.625rem;
}

.menu-item {
  color: $rekap_grey--x-light !important;
}
