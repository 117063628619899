/* --------------------------------- Widths --------------------------------- */
$pageMaxWidth: 1400px;
$innerMaxWidth: 1000px;
$globalMaxWidth: 980px;
$largeCardWith: 768px;
$mediumCardWith: 600px;
$smallCardWith: 450px;
$scrollBarWidth: 10px;

/* --------------------------------- Content Widths --------------------------------- */
$smallContentWidth: 374px;
$mediumContentWidth: 728px;
$largeContentWidth: 1200px;

/* ------------------------------- Breakpoints ------------------------------ */

$desktopWidth: 1440px;
$tabletWidth: 768px;
$mobileWidth: 414px;
$mobileSmallWidth: 360px;

/* ------------------------------- Z - Index's ------------------------------ */
$navBarZIndex: 1000;
$modalZIndex: 1001;
$floatingBarZIndex: 150;
$selectZIndex: 951;

/* --------------------------------- Colors --------------------------------- */

$rekap_black: #313d4e;
$rekap_blue--dark: #262642;
$rekap_brown: #805d0b;
$rekap_cream: #f9f9f9;
$rekap_green: #187841;
$rekap_green--light: #e6ffdd;
$rekap_green--x-light: #f5ffff;
$rekap_grey--dark: #697688;
$rekap_grey--light: #dadde1;
$rekap_grey--x-dark: #44546a;
$rekap_grey--m-light: #f4f5f6;
$rekap_grey--x-light: #ebecee;
$rekap_grey: #b4bbc3;
$rekap_red--dark: #c81b1b;
$rekap_red--light: #eb5757;
$rekap_red--x-light: #ffdad6;
$rekap_red: #e52424;
$rekap_teal--dark: #005454;
$rekap_teal--light: #009d9d;
$rekap_teal--x-light: #1eb5b5;
$rekap_teal--xx-light: #c4f8f8;
$rekap_teal: #008383;
$rekap_white: #ffffff;
$rekap_yellow--dark: #ffd34e;
$rekap_yellow--light: #ffecb0;
$rekap_yellow: #ffde79;

/* --------------------------------- Borders -------------------------------- */

$borderRadius: 4px;
$borderRadius--large: 8px;
$boxShadow: 0 2px 5px rgba(#333, 0.2);
$floatingMenuBoxShadow: 5px 5px 30px 0 #ebebeb;

/* ------------------------------- Typography ------------------------------- */

// Rems
$remFontSizeXXS: 0.75rem;
$remFontSizeXS: 0.88rem;
$remFontSizeS: 1rem;
$remFontSizeM: 1.19rem;
$remFontSizeL: 1.44rem;
$remFontSizeXL: 1.75rem;
$remFontSizeXXL: 2.06rem;
$remFontSizeXXXL: 2.25rem;
$remFontSizeXXXXL: 2.94rem;
$remFontSizeXXXXXL: 3.56rem;

// Font Weight
$fontWeight500: 500;

$button-width--small: 120px;
$button-width--medium: 170px;
$button-width--large: 180px;
$button-width--x-large: 383px;
$button-width--full: 100%;

$text-field-width--small: 112px;
$text-field-width--medium: 156px;
$text-field-width--large: 348px;
$text-field-width--full: 100%;
