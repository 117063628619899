@import '../../styles/variables.scss';

.kswitch-container {
  display: flex;
  color: $rekap_black;
  font-family: 'Inter';
  font-size: $remFontSizeS;
  line-height: 1.5;
  align-items: center;
  column-gap: 0.5rem;

  .kswitch {
    z-index: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $rekap_black;
    font-family: 'Inter';
    font-size: $remFontSizeS;
    line-height: 1.5;

    .kswitch-input {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      z-index: -1;
      position: absolute;
      right: 0.375rem;
      top: -8px;
      display: block;
      margin: 0;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
      outline: none;
      opacity: 0;
      transform: scale(1);
      pointer-events: none;
      transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;

      &:checked {
        right: -10px;
        background-color: $rekap_white;
      }

      &:active {
        opacity: 1;
        transform: scale(0);
        transition: transform 0s, opacity 0s;
      }

      &:disabled {
        opacity: 0;
      }
    }

    .kswitch-switch {
      display: inline-block;
      width: 100%;
      cursor: pointer;

      &::before {
        content: '';
        float: right;
        display: inline-block;
        margin: 0.313rem 0 0.313rem 0.625rem;
        border-radius: 7px;
        width: 2.25rem;
        height: 0.875rem;
        background-color: rgba(34, 31, 31, 0.24);
        vertical-align: top;
        transition: background-color 0.2s, opacity 0.2s;
      }

      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 1rem;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        background-color: $rekap_cream;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
          0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        transition: background-color 0.2s, transform 0.2s;
      }
    }

    .kswitch-input:checked + .kswitch-switch::before {
      background-color: $rekap_grey--x-dark;
      opacity: 0.24;
    }

    .kswitch-input:checked + .kswitch-switch::after {
      background-color: $rekap_grey--x-dark;
      transform: translateX(1rem);
    }

    &:hover > .kswitch-input {
      opacity: 0.04;
    }

    &:hover > .kswitch-input:focus {
      opacity: 0.16;
    }

    .kswitch-input:focus {
      opacity: 0.12;
    }

    .kswitch-input:active + .kswitch-switch::before {
      background-color: rgba(#d3d7dc, 0.6);
    }

    .kswitch-input:checked:active + .kswitch-switch::before {
      background-color: rgba(#a3a9b1, 0.38);
    }

    .kswitch-input:disabled + .kswitch-switch {
      color: $rekap_black;
      cursor: default;

      &::before {
        background-color: rgba(34, 31, 31, 0.24);
      }

      &::after {
        background-color: $rekap_cream;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
          0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }
    }

    .kswitch-input:checked:disabled + .kswitch-switch {
      &::before {
        background-color: $rekap_grey--x-dark;
        opacity: 0.24;
      }

      &::after {
        background-color: $rekap_grey--x-dark;
        transform: translateX(1rem);
      }
    }
  }
}

.ksw-label-left {
  flex-direction: row-reverse;
}

.ksw-label-right {
  flex-direction: row;
  gap: 1rem;
}
