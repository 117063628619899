@import '../../../styles/variables.scss';
@import '../../../styles/mixins/_border.scss';
@import '../../../styles/mixins/_typography.scss';

.custom-notes-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .notes-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .region-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;

      &__title {
        color: $rekap_grey--x-dark;
        text-align: left;
        @include text-styles__title(1, 'medium');
      }

      .quick-notes-container {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        border: none;

        .default-note {
          @include text-styles__body(1);
          color: $rekap_teal;
        }

        .custom-input-container {
          display: flex;
          width: 100%;

          .custom-note-input {
            border: 1px solid $rekap_grey;
            border-radius: $borderRadius;
            padding: 0.25rem;
            width: 100%;
            @include text-styles__body(1);
            resize: vertical;

            textarea::placeholder {
              color: $rekap_grey;
            }
          }
        }
      }
    }

    .notes-accordion-container {
      box-shadow: none;
      margin-bottom: 0px;

      .MuiSvgIcon-root {
        color: $rekap_teal;
      }

      .expanded {
        border-bottom-color: $rekap_teal;
        border-bottom-style: solid;
        border-bottom-width: 1px;

        div {
          margin-bottom: 0px;
        }
      }

      .details {
        margin-bottom: 35px;
      }

      .summary {
        display: flex;
        justify-content: space-between;
        max-height: 50px;
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: 1px;
        right: 0;
        height: 1px;
        content: '';
        opacity: 1;
        background-color: rgba($rekap_black, 0.12);
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &::before {
        display: none;
      }
    }
  }

  .action-container {
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 1.6rem;

    &__save {
      text-align: center;
    }
  }
}

@media (min-width: $tabletWidth) {
  .custom-notes-container {
    .notes-container {
      max-width: 25rem;
    }
  }
}

@media (min-width: $desktopWidth) {
  .custom-notes-container {
    .notes-container {
      flex-direction: row;
      max-width: $globalMaxWidth;
      gap: 10rem;
      align-items: start;
    }
  }
}
