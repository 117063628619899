@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.multi-select-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .multi-select-disabled-contents {
    color: $rekap_black;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
    font-size: $remFontSizeS;
    font-weight: 500;
  }

  .multi-select-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .multi-select-label {
      margin: 0 0 4px 0;
      width: 100%;
      height: 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $rekap_grey--x-dark;
    }
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: $modalZIndex - 1;
    left: 0;
    top: 0;
  }

  .multi-select-wrapper {
    position: relative;

    .multi-select-input-container {
      margin: 0;
      background-color: $rekap_white;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.375rem;
      width: 100%;
      max-width: 100%;
      min-height: 3rem;
      @include border($rekap_grey--light);
      border-radius: $borderRadius;

      &.list-open,
      &:hover {
        @include border($rekap_grey--dark);
      }

      .multi-select-input-text {
        border: none;
        outline: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: $remFontSizeXS;
        line-height: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        letter-spacing: 0.5px;
        display: flex;
        justify-content: space-between;
        color: $rekap_black;

        .multi-select-icon {
          border-left: 1px solid $rekap_grey--light;
          display: flex;
          align-items: center;
          color: $rekap_grey--x-dark;
          padding-left: 8px;
        }
      }

      .multi-select-placeholder {
        color: $rekap_grey;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .multi-select-fixed-width {
      width: 21.75rem;
    }

    .multi-select-input-error-border {
      border-color: $rekap_red;
    }

    .multi-select-input-error {
      color: $rekap_red;
      font-size: 12px;
    }
    .multi-select-tag-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      width: 90%;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      flex-wrap: wrap;
      row-gap: 8px;
      z-index: $selectZIndex;

      .multi-select-tag {
        margin: 0 0.1rem;
        padding: 0.5rem 0.5rem 0.5rem 0.625rem;
        font-size: 0.75rem;
        letter-spacing: 0.4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: $rekap_grey--x-light;
        font-weight: bold;
        line-height: 1rem;
        color: $rekap_grey--x-dark;
        border-radius: 20px;
        width: auto;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $rekap_grey--light;
          color: $rekap_black;
        }

        .multi-select-clear-icon {
          color: $rekap_grey--x-dark;
          margin-left: 0.25rem;
          font-size: 0.85rem;
          width: 1rem;
          height: 1rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    // Removes scroll bar
    .multi-select-tag-container::-webkit-scrollbar {
      display: none;
    }
  }

  .multi-select-list {
    top: 3.25rem;
    position: absolute;
    z-index: $modalZIndex;
    background-color: $rekap_white;
    width: 100%;
    @include border($rekap_grey--light);
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    max-height: 20rem;
    overflow: auto;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    .multi-select-scroll-list {
      width: 100%;
      .multi-select-list-item {
        display: inline-block;
        overflow: scroll;
        width: 100%;
        padding: 0.5rem 0.6rem;
        font-size: $remFontSizeS;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
          background-color: $rekap_cream;
          color: $rekap_black;
        }

        .multi-select-add-option-input {
          border: none;
          font-size: $remFontSizeS;
          white-space: nowrap;
          background-color: transparent;
          outline: none;
          width: 80%;

          &:focus {
            white-space: nowrap;
            text-overflow: ellipsis;
            outline: none;
          }
        }
      }

      // Removes scroll bar
      .multi-select-list-item::-webkit-scrollbar {
        display: none;
      }

      .multi-select-item-selected {
        background-color: $rekap_grey--x-light;
        color: $rekap_black;
        outline: none;

        &:hover {
          background-color: $rekap_grey--light;
          color: $rekap_black;
        }
      }
      .multi-select-add-option {
        background-color: $rekap_white;
        color: $rekap_grey--dark;
        overflow: auto;
        display: flex;
      }
    }

    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      background-color: transparent;
      padding-right: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $rekap_grey--light;
      border-radius: 20px;
      margin-right: 5px;

      &:hover {
        background-color: $rekap_grey;
      }

      &:active {
        background-color: $rekap_grey--dark;
      }
    }
  }
}

.multi-select-disabled {
  pointer-events: none;
  .multi-select-text-container .multi-select-label {
    color: $rekap_grey;
  }
  .multi-select-wrapper {
    .multi-select-input-container {
      background-color: $rekap_grey--x-light;
      border-color: $rekap_grey--light;
    }
    .multi-select-input-container {
      .multi-select-input-text {
        .multi-select-placeholder {
          color: $rekap_grey;
        }
        .multi-select-icon {
          color: $rekap_grey;
          svg path {
            stroke: $rekap_grey;
          }
        }
      }
    }
  }
}

// Note: Necessary Overrides for Dropdown
.multi-select-wrapper {
  button {
    overflow: visible;
    width: inherit;
    margin: inherit;
    padding: inherit;
    border: none;
    background: inherit;
    font: inherit;
    line-height: normal;
    color: inherit;
    text-align: inherit;
    -webkit-appearance: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  p {
    margin: 0;
    padding: 0;
  }
}
