@import '../../../../styles/mixins/typography';
@import './../../../../styles/variables';
@import './../../../../styles/mixins/border';
@import '../../../../styles/mixins/button';

// ---------------------------- styling for modal ---------------------------
.modal-container {
  display: none;
  position: fixed;
  z-index: $modalZIndex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

// this name is stupid im just using it temporarily
.modal-container__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  max-height: 90%;
  width: fit-content;
  overflow: hidden;
}

.show-modal {
  display: flex;
}

// --------------------------------------------------------------------------

/* -------------------------------------------------------------------------- */
/*                      styling for pending delete modal                      */
/* -------------------------------------------------------------------------- */

.pending-delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  padding: 1.5rem;
  box-sizing: border-box;

  &__icon {
    width: 2.1875rem;
    height: 2.1875rem;
  }

  &__header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 4.75rem;
    width: 100%;
  }

  &__message {
    @include text-styles__body(1);
    margin-bottom: 1rem;
  }

  &__title {
    @include text-styles__headline(3);
    margin-bottom: 1.5rem;
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    &--danger {
      @include button($rekap_red);
      width: $button-width--full;
    }

    &--cancel {
      @include button($rekap_white, false, true, $rekap_grey--x-dark);
      width: $button-width--full;
    }
  }
}

.procedures {
  @include border();
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 1rem;
  gap: 1rem;
  box-sizing: border-box;

  &__patient-info {
    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  &__patient-name {
    @include text-styles__title(1);
  }

  &__grouped-procedures {
    width: 15rem;
  }

  &__grouped-label {
    @include text-styles__title(3);
    color: $rekap_grey--x-dark;
  }
}

.dates-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  &__item {
    width: 7.125rem;
  }

  &__date {
    @include text-styles__body(1, medium);
  }

  &__label {
    @include text-styles__title(3);
    color: $rekap_grey--x-dark;
  }
}

@media (min-width: $tabletWidth) {
  .pending-delete-modal {
    &__buttons {
      &--danger {
        width: $button-width--full;
      }

      &--cancel {
        width: $button-width--full;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .pending-delete-modal {
    align-items: start;
    width: 45.5rem;

    &__icon {
      width: 1.75rem;
      height: 1.75rem;
    }

    &__message {
      margin-bottom: 1.5625rem;
    }

    &__title {
      margin-bottom: 0;
      padding-left: 0.125rem;
    }

    &__buttons {
      flex-direction: row-reverse;

      &--danger {
        @include button($rekap_red, true);
      }

      &--cancel {
        @include button($rekap_white, true, true, $rekap_grey--x-dark);
      }
    }

    &__header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      height: 2rem;
      width: 100%;
    }
  }

  .procedures {
    &__grouped-procedures {
      width: 40.5rem;
    }
  }

  .dates-container {
    &__item {
      width: 10rem;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                        styling for post delete modal                       */
/* -------------------------------------------------------------------------- */
.post-delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  padding: 1.5rem;
  box-sizing: border-box;

  &__icon {
    width: 3.125rem;
    height: 3.125rem;
    margin-bottom: 1rem;
  }

  &__header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: auto;
    width: 100%;
  }

  &__title {
    @include text-styles__headline(3);
    margin-bottom: 1.5rem;
    text-align: center;
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    &--danger {
      @include button($rekap_red);
      width: $button-width--full;
    }

    &--cancel {
      @include button($rekap_white, false, true, $rekap_grey--x-dark);
      width: $button-width--full;
    }
  }
}
@media (min-width: $tabletWidth) {
  .post-delete-modal {
    &__buttons {
      &--danger {
        width: $button-width--full;
      }

      &--cancel {
        width: $button-width--full;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .post-delete-modal {
    &__buttons {
      &--danger {
        width: $button-width--full;
      }

      &--cancel {
        width: $button-width--full;
      }
    }
  }
}
