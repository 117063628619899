@import '../../../../styles/variables.scss';

.kaliber-surgery-card-skeleton {
  border-radius: $borderRadius;
  height: 23.375rem;
  width: $smallContentWidth;
  margin-bottom: 2rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@media (min-width: $tabletWidth) {
  .kaliber-surgery-card-skeleton {
    height: 18.125rem;
    width: $mediumContentWidth;
  }
}
@media (min-width: $desktopWidth) {
  .kaliber-surgery-card-skeleton {
    height: 12.813rem;
    width: $largeContentWidth;
  }
}
