@import '../../styles/variables.scss';

.tab-list {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  justify-content: center;

  button {
    outline: none;
    color: $rekap_grey--x-dark;
    border: none;
    background: none;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    &:hover,
    &:active {
      outline: none;
      border: none;
    }
    &:hover {
      color: $rekap_grey;
      border-bottom: 1px solid $rekap_grey;
    }
  }

  .selected {
    color: $rekap_black;
    font-weight: 500;
    border-bottom: 2px solid $rekap_black;
    &:hover {
      color: $rekap_grey;
      border-bottom: 2px solid $rekap_grey;
    }
  }
}
