@import '../../styles/variables.scss';

.facility-confirmation-modal {
  position: absolute;
  overflow-y: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 30rem;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 24px;

  .details-container {
    .details-section-title {
      color: $rekap_black;
      font-size: 1.3rem;
      margin-bottom: 0.7rem;
      font-weight: 500;
      font-family: Inter, Source Sans Pro, Roboto, -apple-system,
        BlinkMacSystemFont;
    }

    .details-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 10px;

      .details-row {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .modal-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 1rem;
    .modal-button {
      margin-left: 10px;
    }
  }
}

.field-group {
  flex: 1;
  min-width: 100px;
  .label {
    font-size: 10px;
    color: $rekap_grey--x-dark;
  }
  .info {
    font-weight: 500;
    color: $rekap_black;
    font-family: Inter, Source Sans Pro, Roboto, -apple-system,
      BlinkMacSystemFont;
  }
  margin-right: 10px;
}
