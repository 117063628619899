@import '../../../../styles/variables.scss';

.videos-title {
  margin: 25px 0 32px;

  h3 {
    color: $rekap_black;
    font-size: 40px;
  }
}
