@import '../../../../styles/variables.scss';

.how-to-container {
  margin-bottom: 25px;

  .how-to-header {
    color: $rekap_black;
    font-size: 40px;
    margin: 1.5625rem 0 2rem;
  }
}

.how-to-photo {
  min-height: 300px;
  width: 18.75rem;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}
.text-container {
  width: 59.375rem;
  display: flex;
  flex-direction: column;
}

#patient-report.text-container {
  min-height: 237px;
}
.text-body {
  width: 50%;
  margin-top: 1.75rem !important;
  margin-bottom: 2.5rem !important;
}
.how-to-content-container {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  .h3 {
    color: $rekap_black;
  }
}
