@import '../../styles/variables.scss';
@import '../../styles/mixins/skeleton';

.help-form {
  margin-top: 1rem;
  margin-bottom: 3rem;

  &__skeleton {
    @include skeleton;
    &--header {
      height: 3.5rem;
    }
    &--info {
      height: 16rem;
    }
  }
}
