@import '../../style.scss';
@import '../../styles/variables.scss';

.mp-card {
  width: 23.375rem;
  min-height: 9.5rem;
  display: flex;
  flex-direction: column;
  margin: 12.5rem auto;
  background: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px;

  &__body {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.0094rem;
    text-align: left;

    color: $rekap_black;
    margin-top: 1.5rem;
  }
  &__btn {
    margin-top: 1rem;
  }
  &__title {
    font-size: 1.1875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: 0.0094rem;
    text-align: left;
    color: $rekap_black;
  }
}

@media (min-width: $tabletWidth) {
  .mp-card {
    width: 45.5rem;
  }
}

@media (min-width: $desktopWidth) {
  .mp-card {
    width: 49.5rem;
  }
}
