@import '../../styles/variables.scss';

.side-drawer {
  position: fixed;
  top: 4rem;
  left: -14.1875rem;
  width: 14.1875rem;
  height: auto;
  background-color: $rekap_white;
  z-index: $modalZIndex;
  border-radius: 0 0 0.25rem 0;

  &.open {
    transform: translateX(100%);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.mobile-nav-list {
  padding: 0;
  width: 14.1875rem;

  .logout-icon {
    margin-right: 0;
  }

  .logout-icon img {
    margin-right: 0;
    position: relative;
    left: -0.125rem;
  }

  .mobile-nav-list-item {
    box-sizing: border-box;
    width: 14.1875rem;
    height: 3rem;
    display: flex;
    color: $rekap_black;
    font-size: $remFontSizeXS;
    font-family: 'Inter';
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    font-weight: 400;
    padding: 0.375rem 1.5rem;
    align-items: center;
    gap: 0.5rem;

    &.active {
      font-weight: 600;
    }

    &:hover {
      background-color: $rekap_cream;
    }
  }
}
