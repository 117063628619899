@import '../../styles/variables.scss';

.expandable-container {
  display: flex;
  flex-direction: column;
  background-color: $rekap_white;
  border-radius: $borderRadius--large;
  box-shadow: $boxShadow;
  padding: 1rem;
  position: relative;
  max-width: $largeContentWidth;

  .expandable-container--header {
    display: flex;

    &__text {
      font-weight: 500;
      font-size: 19px;
      letter-spacing: 0.15px;
      color: $rekap_black;
      flex-grow: 3;
      line-height: 24px;
    }

    &__display_control {
      border: none;
      width: 100%;
      text-align: left;
      background: inherit;
      display: flex;
      padding-block: 0px;
      padding-inline: 0px;
    }
  }

  &__expandable {
    position: absolute;
    background-color: $rekap_white;
    border-radius: $borderRadius--large;
    box-shadow: 0 2px 2px rgba(51, 51, 51, 0.2);
    width: 100%;
    top: 36px;
    left: 0;
  }

  &__alwaysopen {
    display: none;
  }
}

@media (min-width: $desktopWidth) {
  .expandable-container {
    flex-direction: row;
    position: relative;

    .expandable-container--header {
      width: 22.5rem;
    }

    &--outer {
      display: none;
    }

    &__alwaysopen {
      display: flex;
    }
  }
}
