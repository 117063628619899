@import '../../style.scss';
@import '../../styles/variables.scss';

$triangleSize: 6px;
$tooltipMargin: 35px;

@mixin tooltip {
  background-color: $rekap_black;
  position: absolute;
  border-radius: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.625rem 0.625rem 0.625rem 1rem;
  color: $rekap_white;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: $triangleSize;
    margin-left: calc(#{$triangleSize} * -1);
  }
}

.kaliber-tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;

  .tooltip-title {
    color: $rekap_white;
    font-size: $remFontSizeXXS;
  }

  .tooltip {
    &-top {
      @include tooltip;
      top: calc(#{$tooltipMargin} * -1);

      &::before {
        top: 100%;
        border-top-color: $rekap_black;
      }
    }

    &-right {
      @include tooltip;
      left: calc(100% + #{$tooltipMargin});
      top: 50%;
      transform: translateX(0) translateY(-50%);
      &::before {
        left: calc(#{$triangleSize} * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: $rekap_black;
      }
    }

    &-bottom {
      @include tooltip;
      bottom: calc(#{$tooltipMargin} * -1);

      &::before {
        bottom: 100%;
        border-bottom-color: $rekap_black;
      }
    }

    &-left {
      @include tooltip;
      left: auto;
      right: calc(100% + #{$tooltipMargin});
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &::before {
        left: auto;
        right: calc(#{$triangleSize} * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: $rekap_black;
      }
    }
  }
}

@media (min-width: $tabletWidth) {
  .kaliber-tooltip-container {
    width: auto;
  }
}
