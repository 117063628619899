@import '../../styles/variables.scss';
@import '../../styles/mixins/_button.scss';

.select-chip {
  border-radius: 3.75rem;
  padding: 0.5rem 0.75rem;
  @include text-styles__button(2);
  cursor: pointer;

  &__content {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
  }

  &--checked {
    background-color: $rekap_black;
    color: $rekap_white;
  }

  &--unchecked {
    border: 1px solid $rekap_grey--light;
  }
}
