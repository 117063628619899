@import '../../styles/variables.scss';

.vertical-step-item {
  position: relative;
  display: flex;
  padding-bottom: 3.25rem;
  width: 780px;
  min-height: 90px;
  padding: 0 0 1rem 0;
  .vertical-step-circle-container {
    position: relative;
    .vertical-step-circle {
      height: 40px;
      width: 40px;
      background-color: $rekap_grey--dark;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .vertical-step-number {
        color: white;
      }
    }
    .is-open-circle {
      background-color: $rekap_teal--dark;
    }
    .vertical-step-line {
      border-left: 3px $rekap_grey--dark solid;
      min-height: 50%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 46px;
      margin-left: -1.5px;
    }
    .is-open-line {
      max-height: 90%;
      border-color: $rekap_teal--dark;
    }
  }
  .vertical-step-content {
    max-width: 768px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .vertical-step-title {
      height: 40px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: $rekap_black;
    }
    .is-open {
      color: $rekap_black;
    }

    .hide-content {
      transition: max-height 0.2s linear;
      max-height: 100px;
    }
    .show-content {
      max-height: 400px;
    }
  }
}

/*** Non-demo CSS ***/

.kaliber-vertical-stepper-container {
  max-width: 1000px;
  margin: 2rem auto 0;
  .kaliber-vertical-stepper {
    width: fit-content;
    margin: 0 auto;
  }
}
