@import '../../styles/variables.scss';
@import '../../styles/mixins/typography';

.text-btn {
  @include text-styles__button(2);
  color: $rekap_teal;
  background-color: transparent;
  padding: 0.375rem;
  width: fit-content;
  border: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid $rekap_teal;
  }
}
