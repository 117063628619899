@import '../../styles/variables.scss';

.kl-snackbar-container {
  position: fixed;
  top: 6rem;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 18rem;
  height: 1.5rem;
  padding: 1.375rem 1rem;
  background-color: $rekap_black;
  border-radius: 0.25rem;
  .kl-snackbar-text {
    display: inline-block;
    width: 16rem;
    height: 1.5rem;
    color: $rekap_white;
    font-family: Inter;
    font-size: $remFontSizeS;
    font-weight: 400;
    letter-spacing: 0.025rem;
    line-height: 1.5;
  }
  .kl-snackbar-close-icon {
    cursor: pointer;
  }
  .kl-snackbar-loading-animation {
    height: 1.5rem;
    width: 1.5rem;
  }
}
