@import '../variables.scss';
@import './_typography.scss';

@mixin button(
  $background-color,
  $isSmall: false,
  $isBorder: false,
  $border-color: $rekap_teal
) {
  display: flex;
  text-decoration: none;

  @include text-styles__button(1);

  background-color: $background-color;
  color: $rekap_white;
  width: $button-width--full;

  transition: all 0.2s;
  position: relative;

  border: none;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  cursor: default;

  .btn__text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
  }

  &:hover {
    @if $background-color == $rekap_teal {
      background-color: $rekap_teal--light;
    } @else if ($background-color == $rekap_white) {
      @if $border-color == $rekap_teal {
        color: $rekap_teal--light;
        border-color: $rekap_teal--light;
      } @else if $border-color == $rekap_grey--x-dark {
        color: $rekap_grey--dark;
        border-color: $rekap_grey--dark;
      }
    }
  }

  &:active {
    @if $background-color == $rekap_teal {
      background-color: $rekap_teal--dark;
    } @else if ($background-color == $rekap_white) {
      @if $border-color == $rekap_teal {
        color: $rekap_teal--dark;
        border-color: $rekap_teal--dark;
      } @else if $border-color == $rekap_grey--x-dark {
        color: $rekap_black;
        border-color: $rekap_black;
      }
    }
  }

  &:disabled {
    background-color: $rekap_grey;
    color: $rekap_grey--dark;
    &:hover {
      background-color: $rekap_grey;
      color: $rekap_grey--dark;
    }
  }

  @if ($isSmall) {
    width: $button-width--small;
  }

  @if ($isBorder) {
    border: 1px solid $border-color;
    color: $border-color;
  }

  @media (min-width: $tabletWidth) {
    width: $button-width--large;

    @if ($isSmall) {
      width: $button-width--small;
    }
  }
  @media (min-width: $desktopWidth) {
  }
}
