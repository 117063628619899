body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: 'Inter';
}

a {
  all: unset;
  cursor: pointer;
}

button {
  overflow: visible;
  width: inherit;
  margin: inherit;
  border: none;
  background: inherit;
  font: inherit;
  line-height: normal;
  color: inherit;
  text-align: inherit;
  -webkit-appearance: none;
  outline: inherit;
}
