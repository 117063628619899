@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.cpt-procedure-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
  color: $rekap_grey--x-dark;

  .cpt-code-container {
    padding: 0.25rem 0.625rem;
    border-radius: 1.25rem;
    display: inline-flex;
    font-size: $remFontSizeXXS;
    font-weight: bold;
    height: 1.5rem;
    align-items: center;
    align-self: flex-start;
    background-color: $rekap_grey--x-light;

    .cpt-procedure-info-icon:hover .cpt-procedure-description-tooltip {
      visibility: visible;
    }

    .cpt-procedure-info-icon .cpt-procedure-description-tooltip::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 2rem;
      border-width: 0.625rem;
      border-style: solid;
      border-color: $rekap-black transparent transparent transparent;
    }

    .cpt-procedure-info-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.3125rem;
      @include text-styles__button(2);
      position: relative;
    }

    .cpt-procedure-description-tooltip {
      display: inline-block;
      visibility: hidden;
      position: absolute;
      transform: translate(-21%, -100%);
      top: 0;
      left: 0;
      z-index: 1;
      padding: 0.625rem 1rem 0.625rem 1rem;
      border-radius: 0.25rem;
      background-color: $rekap_black;
      color: $rekap_white;
      border-color: $rekap-black;
      width: fit-content;
    }
  }

  .cpt-procedure-common-name {
    margin-left: 2rem;
    @include text-styles__body(1);
  }

  .cpt-procedure-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}

@media (min-width: $tabletWidth) {
  .cpt-procedure-container {
    align-items: flex-start;

    .cpt-procedure-common-name {
      margin-left: 1.5rem;
      @include text-styles__body(1);
    }
  }
}
