@import 'react-datepicker/src/stylesheets/datepicker.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.date-label {
  display: block;
  position: relative;
  color: $rekap_grey--x-dark;
  width: 90%;
  cursor: pointer;
  font-size: 0.75rem;
  margin-bottom: 0.07rem;
  font-weight: 400;
}

.kl-dob-tall-list-page {
  margin-top: 0.1875rem;
  min-width: 10rem;
}

.date-picker {
  @include border($rekap_grey--light);
  border-radius: $borderRadius;

  &:focus {
    outline: none;
    border-color: $rekap_grey--x-dark;
  }
  &:hover {
    outline: none;
    border-color: $rekap_grey--x-dark;
  }
  padding: 10px;
  height: 54px;
  background-image: url('../../assets/icons/date.svg');
  background-repeat: no-repeat;
  background-position: 96% 50%;
  cursor: pointer;
}

::placeholder {
  color: $rekap_grey;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $rekap_teal--dark; // To change selected background colour
}

.react-datepicker__close-icon {
  padding: 0 45px 0 0;
}

.react-datepicker__close-icon::after {
  cursor: default;
  background-color: $rekap_white;
  color: $rekap_grey;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 0px;
  padding-bottom: 2px;
  padding-right: 0.25px;
  font-size: 26px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '×';
}

.SingleDatePickerInput__showClearDate {
  padding-right: 0px;
}

.SingleDatePickerInput_clearDate {
  padding-right: 1.875rem;
  color: $rekap_grey--x-dark;
}
