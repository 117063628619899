@import './variables.scss';

// overlay class used for pop-out components

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: $modalZIndex - 1;
  left: 0;
  top: 0;
}

// disable scrolling. used on the body for the reivew modal
.no-scroll {
  overflow: hidden;
}
