@import '../../styles/variables.scss';

.terms-background {
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba($color: black, $alpha: 0.7);
}

.terms-of-use {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: $rekap_white;
  border-radius: 0.25rem;
  box-shadow: $boxShadow;
  padding: 1.5rem;
  left: 50%;
  top: 50%;
  width: 95%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::-webkit-scrollbar {
    width: 0.625rem;
  }

  ::-webkit-scrollbar-track {
    background: $rekap_grey--light;
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background: $rekap_teal;
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $rekap_teal--light;
  }

  .tou-title {
    color: $rekap_teal;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 1.125rem;
    text-align: center;
  }

  .tou-content {
    padding: 0.625rem;
  }

  .tou {
    width: 95%;
    height: 15rem;
    overflow: auto;
    margin: auto;
    margin-top: 1rem;
    .container-terms {
      width: 90%;
      margin: 0;
    }
  }

  .tou-accept {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .tou-accept-button {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
  }

  .error-message {
    color: $rekap_red;
    font-style: italic;
    width: 100%;
    text-align: center;
    margin-top: 0.67rem;
  }

  .tou-link {
    color: $rekap_teal--light;
  }

  .tou-accept {
    margin-top: 1rem;
  }
}

@media (min-width: $tabletWidth) {
  .terms-of-use {
    .tou {
      height: 40rem;
    }
  }
}

@media (min-width: $desktopWidth) {
  .terms-of-use {
    width: 70%;
    .tou {
      height: 50rem;
    }
    .tou-accept {
      margin-top: 3rem;
      align-self: flex-start;
    }
  }
}
