@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.segmented-control__label {
  margin-bottom: 0.25rem;
  width: 100%;
  height: 24px;
  @include text-styles__title(2);
  // Overriding letter-spacing on text-styles_title(2) to match designs
  letter-spacing: 0.25px;
}

.segmented-control {
  display: none;
  min-width: 16.625rem;
  height: 2rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;

  &__segment {
    min-width: 4rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    @include border($rekap_grey--light);
    cursor: pointer;
    user-select: none;
    @include text-styles__button(2);
    padding: 0 0.625rem;
    color: $rekap_grey--x-dark;

    &:first-child {
      border-radius: 0.625rem 0 0 0.625rem;
    }

    &:last-child {
      border-radius: 0 0.625rem 0.625rem 0;
    }

    &--selected {
      background-color: $rekap_black;
      border-color: $rekap_black;
      color: $rekap_white;
    }
  }
}

@media (min-width: $tabletWidth) {
  .segmented-control {
    display: flex;
  }

  .radio-buttons {
    display: none;
  }
}
