@import '../../../../styles/variables.scss';

.overview-container {
  padding: 22px 0;
  display: flex;

  .overview-description {
    display: flex;
    justify-content: space-between;
    width: 50%;

    .surgery-type {
      font-weight: 600;
    }

    .text-container {
      width: 55%;
      font-size: $remFontSizeS;
      color: $rekap_black;
    }
    .kaliber-logo {
      width: 287px;
      height: auto;
    }
  }
  .overview-surgeon-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .surgeon-photo {
      height: 127px;
      margin-bottom: 10px;
      object-fit: cover;
    }
  }
}

@media (max-width: 700px) {
  .overview-container {
    flex-direction: column;

    .overview-description {
      flex-wrap: wrap-reverse;

      .text-container {
        margin-top: 35px;
        width: 100%;
      }
      .kaliber-logo {
        width: 287px;
        height: auto;
      }
    }
    .overview-surgeon-info {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      .MuiTypography-body1 {
        font-size: 20px;
      }

      .MuiTypography-body2 {
        font-size: 20px;
      }

      .surgeon-photo {
        width: 126px;
        height: 137px;
        margin-bottom: 10px;
        object-fit: cover;
      }

      .player-wrapper {
        position: relative;
        width: 465px;
        height: 281px;
      }

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
