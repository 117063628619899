@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.kaliber-card {
  display: flex;
  flex-direction: column;
  background: $rekap_white;
  padding: 1rem 1.5rem;
  border-radius: $borderRadius--large;
  box-shadow: $boxShadow;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
  max-width: 100%;
  flex-grow: 1;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    @include text-styles__title(1, 'medium', $rekap_black);
    display: flex;
    flex-direction: column;

    &--left {
      text-align: left;
    }
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }

  &__subtitle {
    @include text-styles__body(1, 'normal', $rekap_black, 'italic');
  }
}

.edit-icon {
  margin-left: 0.625rem;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: $tabletWidth) {
  .kaliber-card {
    min-width: 20.375rem;
    &__title {
      flex-direction: row;
      gap: 1rem;
    }
  }

  .header-text {
    flex-direction: row;
    gap: 1rem;
  }
}

@media screen and (min-width: $desktopWidth) {
  .kaliber-card {
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
  }
}
