@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.ascp {
  &__header-title {
    @include text-styles__body(1);
  }

  &__disclaimer {
    @include text-styles__body(3, 'normal', $rekap_grey--dark, 'italic');
    margin-top: 3rem;
  }

  &__card {
    display: grid;
    margin-top: 1rem;

    .ascp-info-section-container {
      .ascp-row-container {
        display: flex;
        flex-direction: column;
        gap: 0;

        .ascp-info-card {
          margin-bottom: 1rem;
          width: 12.5rem;

          .ascp-info-label {
            height: 1rem;
            @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
          }

          .ascp-info-value {
            width: 12.5rem;
            margin-top: 0.25rem;
            @include text-styles__body(1, 'medium');
          }
        }
      }

      .ascp-procedures-label {
        height: 1rem;
        @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
      }

      .ascp-procedures-container {
        @include border($rekap_grey--light);
        border-radius: 0.5rem;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
        padding: 1rem;

        .ascp-patient-region-line-divider {
          border-top: 1px solid $rekap_grey--light;
          margin: 1rem 0;
        }

        .ascp-patient-region-label {
          @include text-styles__body(1, 'medium');
        }

        .ascp-patient-custom-procedure-value {
          @include text-styles__body(2);
          margin-top: 0.5rem;
          max-width: 18.25rem;
          word-wrap: break-word;
        }

        .ascp-patient-custom-procedure-empty-value {
          @include text-styles__body(
            1,
            'normal',
            $rekap_grey--x-dark,
            'italic'
          );
        }
      }
    }
  }
}

.add-surgery-page-container .add-surgery-container {
  .ascp__card .kaliber-card {
    padding-bottom: 0;
    gap: 1.5rem;
  }

  .cpt-procedure-container .cpt-procedure-common-name {
    margin-left: 1.5rem;
    @include text-styles__body(2);
    max-width: 11.25rem;
    word-wrap: break-word;
  }
}

.footer {
  margin-top: 2rem;
}

@media (min-width: $tabletWidth) {
  .add-surgery-page-container .add-surgery-container {
    .ascp__card .kaliber-card {
      margin-bottom: 2rem;
    }

    .cpt-procedure-container .cpt-procedure-common-name {
      @include text-styles__body(1);
      max-width: initial;
      word-wrap: normal;
    }
  }

  .ascp {
    &__disclaimer {
      margin-top: 3.75rem;
    }

    &__card {
      .ascp-info-section-container {
        .ascp-row-container {
          display: flex;
          flex-direction: row;
          gap: 2.5rem;
        }
      }

      .ascp-info-section-container {
        .ascp-procedures-container {
          .ascp-patient-custom-procedure-value {
            @include text-styles__body(1);
            max-width: 68.875rem;
            overflow-wrap: break-word;
          }
        }
      }
    }

    &__add-surgery-button {
      .btn--primary {
        width: 10.625rem;
      }
    }
  }

  .footer {
    margin-top: 2.5rem;
  }
}

@media (min-width: $desktopWidth) {
  .add-surgery-page-container .add-surgery-container .ascp__card .kaliber-card {
    padding-bottom: 0.5rem;
    gap: 1.5rem;
  }

  .ascp {
    &__add-surgery-button {
      .btn--primary {
        width: 11.25rem;
      }
    }
  }
}
