@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins/_typography.scss';

.accordion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-height: 50px;
    padding: 1rem 0 1rem 0;
    border-bottom: 1px solid $rekap_grey--light;

    &__text {
      @include text-styles__body(1);
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      svg path {
        stroke: $rekap_teal;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
