@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.quick-switch {
  &__container {
    width: 100%;
  }

  &__select {
    display: flex;
    flex-direction: column;
  }

  &__tabs {
    width: 100%;
    display: none;
    border-bottom: 1px solid $rekap_grey--x-light;
  }
}

.qs-select {
  &__container {
    flex: 1;
    display: block;
  }

  &__active {
    display: flex;
    color: $rekap_black;
    align-items: center;
    padding-left: 0;
    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    width: 100%;
    height: 1.75rem;
    flex-grow: 0;
    @include text-styles__headline(3);
    text-align: left;
    padding-right: 1rem;
  }

  &__expand-icon {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    width: 2rem;
    height: 2rem;
  }
}

.qs-option-list {
  background-color: $rekap_white;
  @include border;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  max-height: 20rem;
  overflow: auto;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  width: 13.375rem;

  &__button {
    height: 3rem;
    padding: 0.375rem 1.5rem;
    width: 13.375rem;
    @include text-styles__body(2);
    letter-spacing: 0.25px;

    &--active {
      @include text-styles__body(2, 'semibold', $rekap-black);
    }

    &:hover {
      background-color: $rekap_grey--m-light;
    }
  }
  &--expanded {
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
  }
  &--collapsed {
    display: none;
    transition: all 0.5s;
  }
}

.qs-tabs {
  &__container {
    display: flex;
    flex-direction: row;
    height: 2.5rem;
  }

  &__button {
    width: 12.5rem;
    margin-right: 3.75rem;
    height: 100%;
    @include text-styles__title(1);
    text-align: center;
    color: $rekap_grey--x-dark;
    &:hover {
      @include text-styles__title(1, 'medium', $rekap_black);
    }
  }

  &__active {
    border-bottom: 2px solid $rekap_black;
  }
}

@media (min-width: $tabletWidth) {
  .quick-switch {
    &__select {
      display: none;
    }
    &__tabs {
      display: flex;
    }
  }
}
@media (min-width: $desktopWidth) {
}
