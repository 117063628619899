@import '../../style.scss';
@import '../../styles/variables.scss';

.kaliber-divider {
  width: 100%;
  border-bottom: solid;
  border-bottom-color: $rekap_grey;
  border-width: 1px;
  margin-top: 0.96875rem;
  margin-bottom: 0.96875rem;
}
