@import '../../styles/variables.scss';
@import '../../styles/mixins/typography';

.error-link-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-link-card {
  display: flex;
  flex-direction: column;
  margin: 12.5rem 1.25rem;
  background: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  justify-content: space-evenly;
  align-items: center;
  padding: 2.5rem;

  &__body {
    @include text-styles__body(1);
    margin-top: 1.5rem;
  }

  &__title {
    @include text-styles__title(1);
  }
}

.error-link-card__title--large {
  font-size: $remFontSizeXXXXXL;
}

@media (min-width: $desktopWidth) {
  .error-link-card {
    width: 44.5rem;
    height: 6rem;
    padding: 2.5rem;
    justify-content: space-between;

    &__body {
      margin-top: 0;
    }
  }
}
