@import '../../../styles/variables.scss';
@import '../../../styles/mixins/_typography.scss';

.dialog-title {
  color: $rekap_grey--x-dark;
  font-weight: normal;
  font-size: $remFontSizeXL;
}

.dialog-content-text {
  color: $rekap_black;
  font-size: $remFontSizeS;
}

.stepper-button {
  width: 11.25rem;
}

.floating-stepper {
  .report-read-status {
    &__viewed {
      @include text-styles__body(2, 'normal', $rekap_grey--x-dark, 'italic');
      max-width: 11.25rem;
      margin-top: 2rem;
    }

    &__not-viewed {
      @include text-styles__body(2, 'normal', $rekap_red, 'italic');
      max-width: 11.25rem;
      margin-top: 2rem;
    }
  }

  span {
    font-family: 'Inter';
    font-size: $remFontSizeXS;
    line-height: 20px;
  }
}
