@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';
@import '../../styles/mixins/_typography.scss';

.ks-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  .ks-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ks-label {
      margin: 0 0 4px 0;
      width: 100%;
      height: 1rem;
      @include text-styles__title(3, 'normal', $rekap_grey--x-dark);
    }
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: $modalZIndex - 1;
    left: 0;
    top: 0;
  }

  .ks-wrapper {
    position: relative;

    .ks-value-container {
      border: none;
      margin: 0;
      background-color: $rekap_white;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: auto;
      border-radius: 4px;
      color: $rekap_black;
      line-height: 1.5rem;
      letter-spacing: 0.15px;
      font-size: $remFontSizeS;
      font-weight: 500;
    }

    .ks-short {
      height: 40px;
    }

    .ks-tall {
      height: 48px;
    }

    .ks-input-container {
      margin: 0;
      background-color: $rekap_white;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.375rem;
      height: 3rem;
      width: 100%;
      @include border($rekap_grey--light);
      border-radius: $borderRadius;

      &:focus-within,
      &:focus,
      &:active {
        @include border($rekap_grey--dark);
      }

      input:focus-within,
      input:focus-visible {
        border: none;
        outline: none;
      }
      input::placeholder {
        color: $rekap_grey;
      }

      .ks-input-text {
        border: none;
        outline: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: $remFontSizeS;
        line-height: 1.5rem;
        width: 100%;
        text-overflow: ellipsis;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $rekap_black;

        &:focus-within {
          @include border($rekap_grey--x-light);
        }

        .ks-icon {
          border-left: 1px solid $rekap_grey--light;
          display: flex;
          align-items: center;
          color: $rekap_grey--x-dark;
          padding-left: 8px;
          height: 2rem;
        }

        .ks-clear {
          display: flex;
          align-items: center;
          color: $rekap_grey--x-dark;
        }
      }

      .ks-input-selected-item {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 0.375rem;
      }

      .ks-placeholder {
        color: $rekap_grey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ks-focused {
      @include border($rekap_grey--dark);
    }

    .ks-input-error-border {
      border-color: $rekap_red;
    }
    .ks-input-error {
      color: $rekap_red;
      font-size: 12px;
      margin-top: 4px;
      line-height: 16px;
    }
  }

  .ks-list {
    top: 3.25rem;
    position: absolute;
    z-index: $modalZIndex;
    background-color: $rekap_white;
    width: calc(100% - 2px);
    @include border($rekap_grey--light);
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    max-height: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    .ks-scroll-list {
      width: 100%;

      .add-option-input {
        border: none;
        font-size: $remFontSizeS;
        white-space: nowrap;
        background-color: transparent;
        outline: none;
        width: 80%;

        &:focus {
          white-space: nowrap;
          text-overflow: ellipsis;
          background-color: $rekap_grey--x-light;
          outline: none;
        }
      }

      .ks-list-item {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        min-height: 1rem;
        padding: 0.5rem 0.6rem;
        font-size: $remFontSizeS;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
          background-color: $rekap_cream;
          color: $rekap_black;
        }

        .add-option-input {
          border: none;
          font-size: $remFontSizeS;
          white-space: nowrap;
          background-color: transparent;
          outline: none;
          width: 80%;

          &:focus {
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: $rekap_grey--light;
            outline: none;
          }
        }
      }
      // Removes scroll bar
      .ks-list-item::-webkit-scrollbar {
        display: none;
      }

      .ks-add-option {
        background-color: $rekap_grey--x-light;
        overflow: auto;
        display: flex;
      }

      .ks-item-selected {
        background-color: $rekap_grey--x-light;
        color: $rekap_black;
        outline: none;

        &:hover {
          background-color: $rekap_grey--light;
          color: $rekap_black;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.ks-fixed-width {
  max-width: 21.75rem;
}

.ks-disabled {
  pointer-events: none;
  .ks-text-container .ks-label {
    color: $rekap_grey--light;
  }
  .ks-wrapper {
    .ks-input-container {
      border-color: $rekap_grey--light;
    }
    .ks-input-container {
      .ks-input-text {
        .ks-placeholder {
          color: $rekap_grey;
        }
        .ks-icon {
          color: $rekap_grey--light;
        }
      }
    }
  }
}

// Note: Necessary Overrides for Dropdown
.ks-wrapper {
  button {
    overflow: visible;
    width: inherit;
    margin: inherit;
    padding: inherit;
    border: none;
    background: inherit;
    font: inherit;
    line-height: normal;
    color: inherit;
    text-align: inherit;
    -webkit-appearance: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.ks-reg-width {
  max-width: 21.75rem;
}
