@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins/typography';
@import '../../../../../styles/mixins/border';

.report-status-filter {
  position: relative;
  cursor: pointer;

  &__button {
    border-radius: 3.75rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.8125rem;
    background-color: $rekap_white;
    @include border($rekap_grey--light);
    color: $rekap_grey--x-dark;
    margin: 0 0 0 0;
    &--teal {
      border-radius: 3.75rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.125rem;

      @include border($rekap_teal);
      background-color: $rekap_green--x-light;
      margin: 0 0 0 0;
    }
  }

  &__text {
    @include text-styles__button(2);
    padding-right: 0.25rem;
    &--teal {
      @include text-styles__button(2);
      color: $rekap_teal;
      padding-right: 0.25rem;
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 2.8125rem);
    width: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $rekap_white;
    box-shadow: $floatingMenuBoxShadow;
    border-radius: $borderRadius--large;
    padding: 1rem;
    gap: 1rem;
  }
}
.menu {
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}
