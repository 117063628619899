@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/button';
@import '../../../../styles/mixins/_typography.scss';

.asp-missing-contact-information-modal-container {
  .asp-missing-contact-information-modal-text {
    @include text-styles__body(1);
    padding: 1.5rem 0;
  }

  .asp-missing-contact-information-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2.5rem;
    gap: 1rem;

    .btn--primary {
      background-color: $rekap_yellow;
      color: $rekap_brown;
    }

    .btn--edit-information {
      box-sizing: border-box;
      height: 2rem;
      @include button($rekap_white, false, true, $rekap_grey--x-dark);
    }
  }

  .kaliber-modal-container {
    .medium {
      width: 17rem;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
    }

    .kaliber-modal-content-container {
      .asp-missing-contact-information-buttons-container {
        flex-direction: column-reverse;
        height: auto;

        .btn--cancel,
        .btn--primary,
        .btn--edit-information {
          width: 17rem;
          height: 2rem;
          @include text-styles__button(2);
        }
      }
    }
  }
}

@media only screen and (min-width: $tabletWidth) {
  .asp-missing-contact-information-modal-container {
    .kaliber-modal-container {
      .medium {
        width: 41rem;
        min-height: 12rem;
      }

      .kaliber-modal-content-container {
        .asp-missing-contact-information-buttons-container {
          flex-direction: row;

          .btn--cancel,
          .btn--edit-information {
            @include button($rekap_white, false, true, $rekap_grey--x-dark);
          }

          .btn--primary {
            border-color: $rekap_yellow;
            color: $rekap_brown;
            @include text-styles__button(1);
            height: 2.5rem;
          }

          .btn--primary {
            width: 7.5rem;
            box-sizing: border-box;
            border-color: $rekap_yellow;
            background-color: $rekap_yellow;
            color: $rekap_brown;
          }
        }

        .kaliber-modal-close-icon {
          top: 1.5rem;
          right: 1.5rem;
        }

        .kaliber-modal-title-icon-container .kaliber-modal-header-icon img {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}
