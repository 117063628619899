@import '../../styles/variables.scss';
@import '../../styles/mixins/_typography.scss';

.payment-success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5rem 1.25rem;

  &__title {
    @include text-styles__headline(3);
    padding-bottom: 0.5rem;
  }

  &__message {
    @include text-styles__title(3);
  }

  &__report-button {
    .btn--primary {
      margin: 0;
    }
    margin-top: 1.5rem;
    width: 11.25rem;
    height: 2.5rem;
  }
}

@media (min-width: $tabletWidth) {
  .payment-success {
    max-width: 33.875rem;
    margin: 5rem auto;
    width: 100%;

    &__verbiage {
      width: 35rem;
    }

    &__title {
      @include text-styles__headline(2);
    }

    &__message {
      @include text-styles__title(1, 'medium');
    }
  }
}

@media (min-width: $desktopWidth) {
  .payment-success {
    max-width: 62.25rem;
    justify-content: space-between;
    margin: 10rem auto;

    &__verbiage {
      width: 36.75rem;
    }

    &__title {
      @include text-styles__headline(1);
      padding-bottom: 1rem;
    }

    &__report-button {
      margin-top: 5.5rem;
    }
  }
}
