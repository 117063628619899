@import '../../styles/variables.scss';
@import '../../styles/mixins/skeleton';

.noti-container {
  box-shadow: $boxShadow;
  width: 20rem;
  height: 22rem;
  padding: 2rem;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $rekap_white;
  border-radius: $borderRadius;
}

.center {
  text-align: center;
}

.noti-btn-container {
  margin: 2rem auto 1rem auto;
  display: flex;
  justify-content: space-between;
  width: 13.3rem;
}

.confirmation-modal {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 17.5rem;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 1.5rem;
  .modal-title {
    margin-bottom: 1.25rem;
  }
  .modal-body-text {
    margin-bottom: 1.25rem;
  }
  .details-container {
    margin-bottom: 1.25rem;
    .patient-name {
      font-weight: 700;
    }

    &-patient-name {
      font-weight: 700 !important;
    }
  }
}
.modal-button-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.upload-complete-modal {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 1.5rem;
  .modal-title {
    margin-bottom: 1.25rem;
  }
  .modal-body-text {
    margin-bottom: 1.25rem;
  }
  .details-container {
    margin-bottom: 1.25rem;
    .patient-name {
      font-weight: 700;
    }
  }
}
.upload-complete-modal-button-container {
  display: flex;
  justify-content: flex-end;
}

.upload-error-modal {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 17.5rem;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 1.5rem;
  .modal-title {
    margin-bottom: 1.25rem;
    text-align: center;
  }
  .modal-body-text {
    margin-bottom: 1.25rem;
    margin: 0 0.9375rem 0;
  }
  .details-container {
    margin-bottom: 1.25rem;
    .patient-name {
      font-weight: 700;
    }
  }
}
.upload-error-modal-button-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 3.125rem;
}

.progress-modal {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22.5rem;
  height: auto;
  background-color: $rekap_white;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 1.5rem;
  .modal-title {
    margin-bottom: 1.25rem;
  }
  .modal-body-text {
    margin-bottom: 1.25rem;
  }
  .details-container {
    margin-bottom: 1.25rem;
    .patient-name {
      font-weight: 700;
    }
  }
}
.modal-button-container {
  display: flex;
  justify-content: space-between;
}

.error-text {
  display: flex;
  justify-content: center;
  padding-bottom: 1.25rem;
  color: $rekap_red;
}

.MuiSvgIcon-fontSizeLarge {
  font-size: 70px;
  margin-top: 1.25rem;
}

.uploader {
  &__container {
    margin: 5rem auto 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  &__skeleton {
    @include skeleton;
    &--icon {
      height: 10.5rem;
      width: 10.5rem;
      border-radius: 50%;
    }

    &--button {
      height: 3.125rem;
      width: 9.375rem;
    }
  }
  &__button-container {
    display: flex;
    justify-content: space-evenly;
    width: 25rem;
    flex-direction: row;
  }
}
