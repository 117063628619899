@import '../../../../styles/variables.scss';

.primal-picture-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  flex-direction: column;
  gap: 1rem;

  &__iframe,
  &__text {
    width: 60rem;
  }

  &__iframe {
    height: 45rem;
    border: none;
  }

  &__text {
    font-style: italic;
  }

  @media (max-width: #{$desktopWidth - 1px}) {
    &__iframe,
    &__text {
      width: 100%;
    }

    &__iframe {
      height: 45rem;
    }
  }

  @media (max-width: #{$tabletWidth - 1px}) {
    &__iframe,
    &__text {
      width: 100%;
      min-width: 20.125rem;
    }

    &__iframe {
      height: 40rem;
    }
  }
}
