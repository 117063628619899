@import '../../styles/variables.scss';
@import '../../styles/mixins/_border.scss';

.settings-container {
  width: 85%;
  margin: auto;
  margin-top: 3.75rem;
  padding-bottom: 0px;

  .joint-title {
    color: $rekap_grey--x-dark;
    text-align: left;
    margin-top: 1.87rem;
  }

  .switches-container {
    width: max-content;

    .switches {
      display: flex;
      margin-bottom: 0.9rem;
      flex-direction: row;
      align-items: center;

      .labels {
        width: 13rem;
      }

      .data-field {
        margin-left: 20px;
      }

      .disabled {
        opacity: 0.7;
        width: 350px;
      }
    }
  }

  .region-selection {
    width: 100%;
    display: none;
    justify-content: space-around;
    margin-top: 0.98rem;
    margin-bottom: 0.98rem;

    .toggle-button-group {
      justify-content: space-between;
      width: 100%;
      gap: 1.5rem;
    }

    .info-button {
      height: 2.5144rem;
      font-size: $remFontSizeXS;
      text-transform: none;
      @include border(rgba($rekap_black, 0.12));
      border-radius: $borderRadius;
      width: 100%;
    }
    .label-button {
      height: 2.5144rem;
      font-size: $remFontSizeXS;
      text-transform: none;
      @include border(rgba($rekap_black, 0.12));
      border-radius: 6.25rem;
    }
  }

  .title-button {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .edit-btn {
      margin-left: 20px;
      text-decoration: none;
      color: $rekap_grey--x-dark;
      border: none;
      font-size: 18px;
      min-width: 50px;
      height: 50px;
      background-color: transparent;
      transition: 0.3s all;
      font-weight: 500;
      border-radius: $borderRadius;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .post-op-container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    column-gap: 120px;
    margin-bottom: 60px;

    .column-container {
      display: block;
      width: 100%;
      margin-right: 30px;
    }

    .notes-accordion-container {
      box-shadow: none;
      margin-bottom: 0px;

      .MuiSvgIcon-root {
        color: $rekap_teal;
      }

      .expanded {
        border-bottom-color: $rekap_teal;
        border-bottom-style: solid;
        border-bottom-width: 1px;

        div {
          margin-bottom: 0px;
        }
      }

      .details {
        margin-bottom: 35px;
      }

      .summary {
        display: flex;
        justify-content: space-between;
        max-height: 50px;
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: 1px;
        right: 0;
        height: 1px;
        content: '';
        opacity: 1;
        background-color: rgba($rekap_black, 0.12);
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &::before {
        display: none;
      }
    }

    .title-cell {
      padding-right: 1.25rem;
    }
  }

  .post-op-selection-container {
    margin-bottom: 15px;

    .disabled-text-field {
      border-color: rgba($color: $rekap_black, $alpha: 0.23);
      border-style: solid;
      border-radius: 4px 4px 0 0;
      border-width: 1px;
      width: 89%;
      padding: 6.5px 14px;
      font-style: italic;
    }

    .enabled-text-field {
      width: 89%;
      .MuiOutlinedInput-root {
        border-radius: 0 0 4px 4px;
      }
    }

    .single-note-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.box-container {
  margin-top: 0 !important;
}

.heading {
  color: $rekap_black;
  text-align: left;
  margin-top: 1.875rem !important;
}

.sub-heading {
  color: $rekap_black;
  text-align: left;
  margin-top: 1.875rem !important;
}

.label-name {
  color: $rekap_grey--x-dark;
  text-align: left;
  margin-top: 0.75rem !important;
}

.info-row {
  margin-bottom: 0.9375rem !important;
}

.checkbox {
  margin-top: 0.9375rem !important;
}

.btn-container {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 1.6rem;
  margin-bottom: 0.9375rem !important;
  margin-top: 0.625rem !important;

  .save-post-op-instructions-btn {
    text-align: center;
  }
}

@media (max-width: 1270px) {
  .settings-container {
    .post-op-container {
      display: grid;
      grid-template-columns: 33% 33% 33%;
    }
  }
}

@media (max-width: 900px) {
  .settings-container {
    .post-op-container {
      display: grid;
      grid-template-columns: 100%;

      .joint-title {
        display: none;
      }

      .column-container {
        display: none;
      }

      .selected {
        display: block;
      }
    }

    .region-selection {
      display: flex;
    }
  }
}

@media (max-width: 655px) {
  .settings-container {
    .title-button {
      justify-content: space-between;
    }

    .switches-container {
      width: 100%;
      .switches {
        flex-direction: column;
        width: 100%;
        margin-bottom: 1.2rem;

        .labels {
          flex-direction: row-reverse;
          width: 100%;
          margin-left: 0px;
          justify-content: space-between;
          margin-bottom: 0px;
        }

        .data-field {
          width: 100%;
          margin-left: 0px;
        }
      }
    }
  }
}
