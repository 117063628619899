@import '../../styles/variables.scss';
@import '../../styles/mixins/skeleton';

.facility-table {
  table {
    .clickable-row {
      transition: all 0.3s;
      &:hover {
        background-color: rgba($color: $rekap_black, $alpha: 0.1);
        transition: all 0.3s;
        cursor: pointer;
      }
    }
    th {
      color: $rekap_grey--dark;
      font-size: $remFontSizeXS;
      padding: 0.5rem;
      border-top: 1px solid $rekap_grey--light;
      border-bottom: $rekap_grey--light;

      &:nth-child(1) {
        background-image: url(../../assets/icons/sort-icon.svg);
        background-repeat: no-repeat;
        background-position: 80% 50%;
      }
    }
    td {
      min-width: 140px;
      padding: 0.5rem;
      border-bottom: 1px solid $rekap_grey--light;
      position: relative;

      &:last-child {
        border-right: 0;
      }
    }
  }

  .table-skeleton {
    display: flex;
    flex-direction: column;
    .row-skeleton {
      display: flex;
      margin-bottom: 8px;
      margin-top: 8px;
      justify-content: space-between;
    }

    .cell-skeleton {
      width: 10%;
      height: 1.5rem;
    }

    .facility-table {
      display: flex;
      flex-direction: column;
    }

    .filters-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      justify-content: space-between;
      align-items: flex-end;
      .left-container {
        flex-direction: row;
        width: 100%;
        display: flex;

        align-items: flex-end;
      }
      .add-surgery-button {
        align-self: flex-end;
      }
    }
    .filter {
      margin-right: 24px;
    }

    .menu-container {
      display: flex;
      min-width: 80vw;
      flex-direction: row;
      justify-content: flex-end;
    }

    .filter-title {
      font-size: 0.75rem !important;
      margin-bottom: 0.07rem !important;
    }
    .filter-select {
      height: 54px;
    }

    input[type='search']::-webkit-search-cancel-button {
      position: relative;
      right: 15px;
      -webkit-appearance: none;
      height: 10px;
      width: 10px;
      margin-left: 0.4em;
      background: $rekap_grey--light;
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
      cursor: pointer;
    }

    .Empty-text {
      padding-top: 1.3rem;
      color: $rekap_black;
      text-align: center;
    }

    .Kaliber-button {
      margin-left: 25px;
      background: $rekap_teal--dark;
      color: $rekap_white;
      width: 10rem;
      height: 4rem;
      box-shadow: $boxShadow;
      font-weight: 500;
    }

    .Action {
      font-weight: 600;
      color: $rekap_grey--x-dark;
      text-decoration: underline;
    }

    .Patient-name {
      color: $rekap_grey--x-dark;
    }

    @media (max-width: 600px) {
      .facility-table {
        .filter {
          width: 100%;
          margin-right: 0px !important;

          .search-box-container {
            width: 100%;
          }
        }

        tr {
          display: flex;
          flex-direction: column;
        }

        thead tr th {
          border-top: none;
        }

        thead tr th:nth-child(n) {
          display: none;
        }

        thead tr th:nth-child(1) {
          display: block;
          background-position: 98% 50%;
        }

        tbody tr {
          display: grid;
          grid-template-areas:
            'name facilities'
            'address address'
            'phone phone'
            'email email';
          grid-template-columns: 2fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          margin: 8px auto 8px auto;
          padding: 10px 0px;
        }

        tbody tr td {
          margin: -9px 0px;
        }

        tbody tr td:nth-child(n) {
          border: none;
        }

        tbody tr td:nth-child(3) {
          grid-area: address;
        }

        tbody tr td:nth-child(7) {
          grid-column: 4;
          grid-area: email;
          border-bottom: 2px solid $rekap_grey--light;
        }

        tbody tr td:nth-child(4) {
          grid-area: phone;
        }

        tbody tr td:nth-child(5) {
          display: none;
        }

        tbody tr td:nth-child(6) {
          display: none;
        }

        tbody tr td:nth-child(8) {
          display: none;
        }
      }
    }
  }
}

.facilities-page {
  // Copied from the App.scss file for spacing from the headerbar
  margin: 68px auto 0;
  max-width: $largeContentWidth;
  width: 100%;

  &__skeleton {
    @include skeleton;
    &--header {
      height: 3.5rem;
    }
    &--table {
      height: 80vh;
    }
  }

  &__skeleton-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
}
