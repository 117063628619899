.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-title {
    display: flex;
    justify-content: center;
  }

  .kaliber-usb {
    display: block;
    height: 10.56rem;
    width: 8.69rem;
  }

  .uploader-btn-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
}

.files-header {
  font-style: italic;
  display: inline-block;
}

.upload-view {
  text-align: center;
}

.file-row {
  display: flex;
  justify-content: center;
}

.file-content {
  display: flex;
  align-items: center;

  .files-header {
    margin-right: 2px;
  }

  .remove-btn {
    margin-left: 2px;
  }
}
