@import '../../../../styles/variables';
@import '../../../../styles/mixins/typography';
@import '../../../../styles/mixins/border';

.searchbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  background: $rekap_white;
  border-radius: $borderRadius;
  @include border($rekap_grey--light);

  &__input {
    @include text-styles__body(1);
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0.5rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $rekap_grey;
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $rekap_grey;
  }
}

@media (min-width: $desktopWidth) {
  .searchbar {
    width: 24rem;

    &__input {
      @include text-styles__body(1);
      height: 100%;
      width: 100%;
      background: transparent;
      border: none;
      padding: 0.5rem;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $rekap_grey;
      }
    }

    &__icon {
      width: 2.5rem;
      height: 2.5rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $rekap_grey;
    }
  }
}
