@import '../../../../../../../../styles/variables.scss';
@import '../../../../../../../../styles/mixins/_typography.scss';
@import '../../../../../../../../styles/mixins/_center.scss';

.thumbnail {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__edit_button {
    position: relative;
    width: 20.375rem;
    height: 20.375rem;
  }

  .phase-icon-bar {
    position: absolute;

    &__icon {
      width: 2rem;
      height: 2rem;
      margin: 0.5rem 0 0 0.5rem;
    }
  }

  .review-image {
    border-radius: $borderRadius--large;
    width: 20.375rem;
    height: 20.375rem;
    object-fit: cover;

    &:hover {
      opacity: 0.6;
    }
  }

  .review-image__excluded__overlay {
    position: absolute;
    top: 0;
    background: rgba($rekap_black, 0.5);
    width: 100%;
    height: 100%;
    left: 0;

    .review-image__excluded__line {
      @include center(true, true, 45deg);
      height: 140%;
      z-index: $floatingBarZIndex;
      border-left: 0.313rem solid $rekap_red;
      border-radius: $borderRadius;
    }
  }

  .quick-note-selection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: self-start;

    gap: 0.25rem;

    &__title {
      @include text-styles__title(3);
    }

    &__options {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__tag-container {
      white-space: nowrap;
      width: 100%;
      max-width: 20.375rem;

      .toggle-check-icon {
        width: 1rem;
        height: 1rem;
        margin-right: 0.375rem;
      }
    }

    &__content {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__detailed-content {
      @include text-styles__body(1);
    }
  }
}

@media (min-width: $tabletWidth) {
  .thumbnail {
    &__edit_button {
      width: 13.25rem;
      height: 13.25rem;
    }

    .review-image {
      width: 13.25rem;
      height: 13.25rem;
      object-fit: cover;
    }

    .quick-note-selection {
      &__tag-container {
        width: 100%;
        max-width: 13.25rem;
      }
    }
  }
}

@media (min-width: $desktopWidth) {
  .thumbnail {
    &__edit_button {
      width: 16.625rem;
      height: 16.625rem;
    }

    .review-image {
      width: 16.625rem;
      height: 16.625rem;
      object-fit: cover;
    }

    .quick-note-selection {
      &__tag-container {
        width: 100%;
        max-width: 16.625rem;
      }
    }
  }
}
