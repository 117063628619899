@import '../../../../styles/variables.scss';

.images-title {
  margin: 25px 0 32px;
  h3 {
    color: $rekap_black;
    font-size: 40px;
    text-align: center;
  }
}
.images-container {
  margin: 2.8125rem auto 0 auto;
  max-width: 60rem;
  display: flex;
  justify-content: space-between;
  .images-carousel {
    width: 100%;
    .carousel {
      margin-bottom: 1.875rem;
      .carousel-image {
        height: 500px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.image-body-text {
  margin: 0.625rem 0 1.5625rem 0;
}

.grey-font {
  color: $rekap_grey--dark;
}

.center {
  text-align: center;
}

.surgery-phase-header {
  font-size: 20px;
}

.block-group {
  display: flex;
  justify-content: space-between;
  margin: 1.25rem 0;
  .block-divider {
    border: none;
    width: 32%;
    height: 12px;
    background: $rekap_grey;
    transition: all 0.5s ease;

    &:hover {
      cursor: pointer;
      background: $rekap_teal--dark;
    }
  }
  .current-block {
    background: $rekap_teal--dark;
  }
}

@media (max-width: 900px) {
  .surgery-images-container {
    margin-bottom: 1.5625rem;
  }

  .images-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .images-carousel {
      width: 100%;
      .carousel {
        margin-bottom: 4.5rem;
        .carousel-image {
          transform: translateX(-65px);
          height: 400px;
          width: 125%;
          object-fit: cover;
        }
      }
    }
  }
}
